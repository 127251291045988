import {
  useEffect,
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Dialog, DialogTitle, IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "0 30px 30px 30px",
    overflowX: "hidden",
    position: "relative",
  },
  "& .MuiDialogTitle-root": {
    ...theme.typography.h4,
    padding: "17px 32px 52px 32px",
    color: "#FFFFFF",
    zIndex: 1,
    fontSize: 30,

    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: 330,
      height: 88,
      zIndex: -1,
      background:
        "linear-gradient(180deg, rgba(88, 69, 126, 0.8) 0%, rgba(151, 99, 255, 0.8) 100%)",
      boxShadow: "0px 20px 40px rgba(0, 0, 0, 0.15)",
      borderRadius: "0px 0px 50px 50px",
      [theme.breakpoints.down("md")]: {
        width: 189,
        height: 40,
      },
    },
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(4),
    width: 600,
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 16,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      {children}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Bootbox = ({ children, ...props }, innerRef) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(props?.title || null);
  const [content, setContent] = useState(children || "");
  // modelId is state for edit bootbox
  const [modelId, setModelId] = useState(null);

  const show = useCallback((enable = true) => {
    setOpen(enable);
  }, []);

  const hide = useCallback((enable = true) => {
    setOpen(!enable);
  }, []);

  const getModelId = useCallback(() => modelId, [modelId]);
  const saveModelId = useCallback((id) => setModelId(id), []);

  useImperativeHandle(
    innerRef,
    () => ({
      show: show,
      hide: hide,
      title: (t) => setTitle(t),
      content: (c) => setContent(c),
      setModelId: saveModelId,
      getModelId: getModelId,
    }),
    [hide, show, getModelId, saveModelId]
  );

  useEffect(() => {
    setTitle(props?.title);
  }, [props?.title]);

  useEffect(() => {
    setContent(children);
  }, [children]);

  return (
    <BootstrapDialog
      onClose={() => hide()}
      aria-labelledby="customized-dialog-title"
      open={open}
      {...props}
    >
      <BootstrapDialogTitle onClose={() => hide()}>
        {title}
      </BootstrapDialogTitle>
      {content}
    </BootstrapDialog>
  );
};

export default forwardRef(Bootbox);
