import { useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container } from "@mui/material";
import BlogTitleSection from "../components/sections/BlogTitleSection";
import BlogCategorySection from "../components/sections/BlogCategorySection";
import BlogCardSection from "../components/sections/BlogCardSection";
import BlogListSection from "../components/sections/BlogListSection";
import SEOTag from "../components/tools/SEOTag";

const BlogListPage = () => {
  const ref = useRef(null);
  const { categoryId } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <SEOTag
        title="管理公司懶人包 | Carebridge"
        description={
          "Carebridge 提供不同的資源和案例研究，幫助你輕鬆診所管理解決問題。"
        }
        name="Carebridge| 凱爾診所管理系統"
        type="article"
      />
      <Container maxWidth="lg">
        <BlogTitleSection
          title="資源站"
          description="閱讀更多關於Carebridge更新、案例研究和更多信息!"
        />
        <BlogCardSection type="banner" />

        <BlogCategorySection
          slug={categoryId}
          title="更多信息"
          listRef={ref}
          //handleQuery={handleQuery}
        />

        <BlogListSection category_slug={categoryId} ref={ref} />
      </Container>
    </>
  );
};

export default BlogListPage;
