import React from "react";
import { Container, Grid, createTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import CirclesImg from "../media/img/freeProgram/CirclesImg2.png";
import wavesImg from "../media/img/freeProgram/waves.png";
import spiral from "../media/img/freeProgram/Spiral.png";
import line from "../media/img/freeProgram/Line.png";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontFamily: "Noto Sans TC",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "80px",
      lineHeight: "100px",
      textTransform: "capitalize",
      color: "#58457E",
      marginBottom: "30px",
      position: "relative",
      left: "20px",
      "& :after": {
        content: "''",
        position: "absolute",
        top: "50%",
        left: 0,
        transform: "translate(-30%, -50%)",
        backgroundImage: `url(${spiral}) `,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top left",
        zIndex: "-1",
        width: "200px",
        height: "200px",
      },
    },
    hightLightText: {
      color: "#B400AC",
      display: "inline-block",
      lineHeight: "0px",
      "& img": {
        position: "relative",
        top: "32px",
      },
    },
    para: {
      fontFamily: "Noto Sans TC",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "32px",
      color: "#2C497F",
      marginTop: "0px",
      paddingRight: "30%",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "0%",
      },
    },
    button: {
      background: "#B400AC",
      borderRadius: "30px",
      padding: "20px 40px",
      fontFamily: "Noto Sans TC",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      color: "white",
      cursor: "pointer",
      shadow: "none",
      marginTop: "50px",
    },
    customBG: {
      marginTop: "100px",
      backgroundImage: `url(${CirclesImg})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top right",
      backgroundSize: "50% auto",
      paddingBottom: "150px",
      [theme.breakpoints.down("md")]: {
        marginTop: "0px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom right",
        backgroundSize: "80% auto",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "0px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom right",
        backgroundSize: "220% auto",
      },
    },
    offerInfo: {
      fontFamily: "Noto Sans TC",
      fontStyle: "normal",
      fontWeight: 700,
      background: "rgba(255, 255, 255, 0.69)",
      border: "1px solid #DCDCDC",
      backdropFilter: "blur(20px)",
      borderRadius: "12px",
      padding: "24px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        display: "block !important",
        alignItems: "start",
        justifyContent: "flex-start",
      },
    },
    tickUl: {
      listStyleType: "none",
      "& li:before": {
        content: '"\\2713"',
        marginRight: "20px",
      },
      "& li": {
        marginTop: "5px",
      },
    },
    infoBox: {
      background: "#B400AC",
      border: "1px solid #DCDCDC",
      borderRadius: "12px",
      backdropFilter: "blur(20px)",
      padding: "20px 10px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& h2": {
        fontFamily: "Noto Sans TC",
        fontStyle: "normal",
        fontWeight: 700,
        margin: "0px",
        fontSize: "40px",
        color: "white",
        opacity: "0.5",
        textAlign: "center",
      },
    },
    offer: {
      fontFamily: "Noto Sans TC",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "62px",
      textTransform: "capitalize",
      color: "white",
      margin: "0px",
      textAlign: "right",
      [theme.breakpoints.down("sm")]: {
        fontSize: "42px",
      },
    },
    offerBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      margin: "20px 0px",
      "& div": {
        width: "60px",
        height: "60px",
        borderRadius: "50px",
        border: "6px solid #FFFFFF",
        marginRight: "20px",
      },
    },
    tranparentHeading: {
      color: "#EC9DE8",
    },
  })
);

const FreeProgram = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.customBG}>
      <Container maxWidth="lg">
        <Grid container spacing={6}>
          <Grid item lg={6} xs={12} spacing={6}>
            <h2 className={classes.title}>
              Get our{" "}
              <span className={classes.hightLightText}>
                <div>
                  <div>Free</div>{" "}
                  <img src={line} alt="CareBridge 免費永久診所管理系統" />
                </div>{" "}
              </span>
              Program
            </h2>
            <p className={classes.para}>
              你需要的一切，永遠免费。無限的預約、無限的診歷，無限的配藥資料等。在任何时候都可以升级。
            </p>
            <a href="/#TrialFormSection">
              <button className={classes.button}>Get Started</button>
            </a>
          </Grid>
          <Grid className={classes.rightColumn} item lg={6} xs={12}>
            <h2 className={classes.offer}> CareBridge</h2>
            <h2 className={`${classes.tranparentHeading} ${classes.offer}`}>
              {" "}
              診所管理系統
            </h2>
            <div className={classes.offerBox}>
              <div />
              <h2 className={classes.offer}> Special Offer</h2>
            </div>
            <div className={classes.offerInfo}>
              <ul className={classes.tickUl}>
                <li>1 user</li>
                <li>無限事件類型</li>
                <li>社區支持</li>
                <li>在線知識庫**</li>
                <li>診歷系統</li>
                <li>預約系統</li>
                <li>配藥系統</li>
                <li>網頁製作系統</li>
                <li>簡單及快速設置</li>
                <li>可以在任何地方登入</li>
                <li>以及更多強大的功能</li>
              </ul>
              <div className={classes.infoBox}>
                <img
                  src={wavesImg}
                  style={{ width: "70%" }}
                  alt="CareBridge 免費永久診所管理系統"
                />
                <h2>
                  Free
                  <br /> Program
                </h2>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default FreeProgram;
