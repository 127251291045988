import { InputBase } from "@mui/material";
import { styled } from "@mui/system";

const InputGroupWrapper = styled("div", {
  name: "InputGroupWrapper",
})(({ theme }) => ({
  display: "flex",
  border: "2px solid #58457E",
  borderRadius: 30,
  boxShadow: "0 4px 0 #58457E",
  overflow: "hidden",

  "& .MuiInputBase-root": {
    color: "rgba(44, 73, 127, 1)",
    fontSize: 20,
    flex: "1 1 auto",
    borderRadius: "30px 0px 0px 30px",
    padding: "27px 16px",
    background: "#EBEFFE",

    ".MuiInputBase-input": {
      padding: 0,
    },

    "&::placeholder": {
      color: "rgba(44, 73, 127, 0.5)",
    },

    [theme.breakpoints.down("md")]: {
      padding: "18px 16px",
      fontSize: 13,
    },
  },

  "& .addon .MuiButtonBase-root": {
    borderRadius: "0",
    [theme.breakpoints.down("md")]: {
      fontSize: 13,
      padding: 19,
    },
  },
}));
const InputGroup = ({ addOn, ...rest }) => {
  return (
    <InputGroupWrapper className="InputGroupWrapper">
      <InputBase {...rest} />
      {addOn && <span className="addon">{addOn}</span>}
    </InputGroupWrapper>
  );
};
export default InputGroup;
