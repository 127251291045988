import * as React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

//import { styled as MuiStyled } from "@mui/system";

import MyButton from "../components/tools/MyButton";
import Bootbox from "../components/tools/Bootbox";

import CTASection from "../components/sections/CTASection";

import ClickIcon from "../media/svg/icon/click.svg";
import StarIcon from "../media/svg/icon/star.svg";

const PricingPage = () => {
  const bootboxRef = React.useRef(null);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/#TrialFormSection");
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Bootbox title="提交" ref={bootboxRef}></Bootbox>
      <PricingWrapper>
        <ContentWrapper>
          <TitleWrapper>
            <Title>方案計劃費用</Title>
            <Description>
              限時體驗名額使用所有診所管理系統功能！不知道適合哪種方案計畫？立即聯繫銷售顧問
              <br />
              解答所有診所管理疑問，並搶先取得"限時體驗名額"！
            </Description>
          </TitleWrapper>
          <PriceCardGroupWrapper>
            {/*
            <Card01Wrapper>
              {" "}
              <Card01Title>One</Card01Title>{" "}
              <Card01Price>
                {" "}
                <div className="pricing">體驗</div>{" "}
                <div className="intro">
                  {" "}
                  你需要的一切，永遠免费。無限的預約、無限的診歷，無限的配藥資料等。在任何时候都可以升级。{" "}
                </div>{" "}
              </Card01Price>{" "}
              <Card01Description>
                {" "}
                <div className="d01">
                  {" "}
                  <img className="icon" src={ClickIcon} alt="" />{" "}
                  <div className="d01Content">
                    {" "}
                    <span className="num">
                      {" "}
                      1<space></space>{" "}
                    </span>{" "}
                    user{" "}
                  </div>{" "}
                </div>{" "}
                <div className="d02">
                  {" "}
                  <img className="icon" src={ClickIcon} alt="" />{" "}
                  <div className="d02Content">無限事件類型</div>{" "}
                </div>{" "}
                <div className="d03">
                  {" "}
                  <img className="icon" src={ClickIcon} alt="" />{" "}
                  <div className="d03Content">社區支持</div>{" "}
                </div>{" "}
                <div className="d04">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d04Content">
                    在線知識庫
                    <span className="starPoint">**</span>
                  </div>
                </div>
                <div className="d05">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d05Content">診歷系統</div>
                </div>
                <div className="d06">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d06Content">預約系統</div>
                </div>
                <div className="d07">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d07Content">配藥系統</div>
                </div>
                <div className="d08">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d08Content">簡單及快速設置</div>
                </div>
                <div className="d09">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d09Content">可以在任何地方登入</div>
                </div>
                <div className="d10">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d10Content">以及更多強大的功能</div>
                </div>
              </Card01Description>
              <MyButton name="red-thinText-button" onClick={handleClick}>
                顧問咨詢
              </MyButton>
            </Card01Wrapper>
						*/}
            <Card02Wrapper>
              {" "}
              <Card02Title>
              <div className="title-text">
              <span className="title">Teams</span>
                小型診所最佳選擇
              </div>
                <span className="starIcon">
                  <img src={StarIcon} alt="" />
                </span>
              </Card02Title>{" "}
              <Card02Price>
                {" "}
                <div className="pricing">
                  <span className="month">MOP</span>
                  <span className="priceNum">880</span>
                  <span className="bigStarPoint">*</span>
                  <span className="month">/月</span>
                  <br/>
                </div>{" "}
                <div className="intro">
                  {" "}
                  <span className="month">首次設置費用MOP 0</span>
                 {" "}
                </div>{" "}
                <div className="intro">
                  {" "}
                  團隊工作更有效率，發揮高效流程{" "}
                </div>{" "}
              </Card02Price>{" "}
              <Card02Description>
                {" "}
                <div className="d01">
                  {" "}
                  <img className="icon" src={ClickIcon} alt="" />{" "}
                  <div className="d01Content">
                    {" "}
                    <span className="num">
                      {" "}
                      2<space></space>{" "}
                    </span>{" "}
                    user{" "}
                  </div>{" "}
                </div>{" "}
                <div className="d02">
                  {" "}
                  <img className="icon" src={ClickIcon} alt="" />{" "}
                  <div className="d02Content">無限事件類型</div>{" "}
                </div>{" "}
                <div className="d03">
                  {" "}
                  <img className="icon" src={ClickIcon} alt="" />{" "}
                  <div className="d03Content">社區支持</div>{" "}
                </div>{" "}
                <div className="d04">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d04Content">
                    電子郵件支持
                    <span className="starPoint">***</span>
                  </div>
                </div>
                <div className="d05">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d05Content">
                    遠程控制支持
                    <span className="starPoint">^</span>
                  </div>
                </div>
                <div className="d06">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d06Content">
                    遠程線上教學服務
                    <span className="starPoint">^^</span>
                  </div>
                </div>
                <div className="d07">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d07Content">診歷系統</div>
                </div>
                <div className="d08">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d08Content">預約系統</div>
                </div>
                <div className="d09">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d09Content">配藥系統</div>
                </div>
                <div className="d10">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d10Content">簡單及快速設置</div>
                </div>
                <div className="d11">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d11Content">可以在任何地方登入</div>
                </div>
                <div className="d12">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d12Content">以及更多強大的功能</div>
                </div>
              </Card02Description>
              <MyButton name="red-thinText-button" onClick={handleClick}>
                顧問咨詢
              </MyButton>
            </Card02Wrapper>
            <Card03Wrapper>
              {" "}
              <Card03Title>
              <div className="title-text">
                <span className="title">Plus</span>
                一般診所及醫療中心首選
             </div>
                </Card03Title>{" "}
              <Card03Price>
                {" "}
                <div className="pricing">
                  <span className="month">MOP</span>
                  <span className="priceNum">2400</span>
                  <span className="bigStarPoint">*</span>
                  <span className="month">/月</span>
                </div>{" "}
                <div className="intro">
                  {" "}
                  <span className="month">首次設置費用MOP 0</span>
                 {" "}
                </div>{" "}
                <div className="intro">
                  {" "}
                  最好的診所管理工具，全面掌控，安全保障{" "}
                </div>{" "}
              </Card03Price>{" "}
              <Card03Description>
                {" "}
                <div className="d01">
                  {" "}
                  <img className="icon" src={ClickIcon} alt="" />{" "}
                  <div className="d01Content">
                    {" "}
                    <span className="num">
                      {" "}
                      5<space></space>{" "}
                    </span>{" "}
                    user{" "}
                  </div>{" "}
                </div>{" "}
                <div className="d02">
                  {" "}
                  <img className="icon" src={ClickIcon} alt="" />{" "}
                  <div className="d02Content">無限事件類型</div>{" "}
                </div>{" "}
                <div className="d03">
                  {" "}
                  <img className="icon" src={ClickIcon} alt="" />{" "}
                  <div className="d03Content">社區支持</div>{" "}
                </div>{" "}
                <div className="d04">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d04Content">
                    電子郵件支持
                    <span className="starPoint">*</span>
                  </div>
                </div>
                <div className="d05">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d05Content">
                    遠程控制支持
                    <span className="starPoint">*</span>
                  </div>
                </div>
                <div className="d06">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d06Content">
                    遠程線上教學服務
                    <span className="starPoint">*</span>
                  </div>
                </div>
                <div className="d07">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d07Content">診歷系統</div>
                </div>
                <div className="d08">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d08Content">預約系統</div>
                </div>
                <div className="d09">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d09Content">配藥系統</div>
                </div>
                <div className="d10">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d10Content">簡單及快速設置</div>
                </div>
                <div className="d11">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d11Content">可以在任何地方登入</div>
                </div>
                <div className="d12">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d12Content">以及更多強大的功能</div>
                </div>
              </Card03Description>
              <MyButton name="red-thinText-button" onClick={handleClick}>
                顧問咨詢
              </MyButton>
            </Card03Wrapper>
            <Card04Wrapper>
              {" "}
              <Card04Title>
              <div className="title-text">
                <span className="title"> Platform </span>
                多分店及地區診所及醫療中心首選
             </div> 
                </Card04Title>{" "}
              <Card04Price>
                {" "}
                <div className="pricing">
                  <span className="month">MOP</span>
                  <span className="priceNum">7600</span>
                  <span className="bigStarPoint">*</span>
                  <span className="month">/月</span>
                </div>{" "}
                <div className="intro">
                  {" "}
                  <span className="month">首次設置費用MOP 0</span>
                 {" "}
                </div>{" "}
                <div className="intro">
                  {" "}
                  多地點診所業務全方位管理，輕鬆掌握{" "}
                </div>{" "}
              </Card04Price>{" "}
              <Card04Description>
                {" "}
                <div className="d01">
                  {" "}
                  <img className="icon" src={ClickIcon} alt="" />{" "}
                  <div className="d01Content">
                    {" "}
                    <span className="num">
                      {" "}
                      20<space></space>{" "}
                    </span>{" "}
                    user{" "}
                  </div>{" "}
                </div>{" "}
                <div className="d02">
                  {" "}
                  <img className="icon" src={ClickIcon} alt="" />{" "}
                  <div className="d02Content">無限事件類型</div>{" "}
                </div>{" "}
                <div className="d03">
                  {" "}
                  <img className="icon" src={ClickIcon} alt="" />{" "}
                  <div className="d03Content">社區支持</div>{" "}
                </div>{" "}
                <div className="d04">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d04Content">
                    電子郵件支持
                    <span className="starPoint">***</span>
                  </div>
                </div>
                <div className="d05">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d05Content">
                    遠程控制支持
                    <span className="starPoint">^</span>
                  </div>
                </div>
                <div className="d06">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d06Content">
                    遠程線上教學服務
                    <span className="starPoint">^^</span>
                  </div>
                </div>
                <div className="d07">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d07Content">診歷系統</div>
                </div>
                <div className="d08">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d08Content">預約系統</div>
                </div>
                <div className="d09">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d09Content">配藥系統</div>
                </div>
                <div className="d10">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d10Content">簡單及快速設置</div>
                </div>
                <div className="d11">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d11Content">可以在任何地方登入</div>
                </div>
                <div className="d12">
                  <img className="icon" src={ClickIcon} alt="" />
                  <div className="d12Content">以及更多強大的功能</div>
                </div>
              </Card04Description>
              <MyButton name="red-thinText-button" onClick={handleClick}>
                顧問咨詢
              </MyButton>
            </Card04Wrapper>
          </PriceCardGroupWrapper>
          <SurchargesWrapper>
            <SurchargesTitle>增值服務及附加費用：</SurchargesTitle>
            <SurchargesDescription>
              <div className="sd01">
                <span className="sdTitle">1.額外線上協助支援服務：</span>
                <span className="sdDescription">每2小時 $300</span>
              </div>
              <div className="sd02">
                <span className="sdTitle">
                  2.額外系統教學，提供幫助用戶更好地掌握系統操作：
                </span>
                <span className="sdDescription">$1600/次</span>
              </div>
            </SurchargesDescription>
          </SurchargesWrapper>
          <RemarkWrapper>
            <RemarkTitle>備註：</RemarkTitle>
            <RemarkDescription>
              <div className="rd01">
                <span className="starPoint">*</span>
                <span className="rdTitle">價格幣別：</span>
                <span className="rdDescription">HKD - Hong Kong dollar</span>
              </div>
              <div className="rd02">
                <span className="starPoint">**</span>
                <span className="rdTitle">在線知識庫：</span>
                <span className="rdDescription">
                  用戶可以在系統線上知識，包含系統常見問題的答案和解決方案，用戶可以在需要的自行查閱和觀看。
                </span>
              </div>
              <div className="rd03">
                <span className="starPoint">***</span>
                <span className="rdTitle">電子郵件支持：</span>
                <span className="rdDescription">
                  用戶可以發送問題或問題報告，並在處理他們的請求時給出預計的響應時間。
                </span>
              </div>
              <div className="rd04">
                <span className="starPoint">^</span>
                <span className="rdTitle">價遠程控制支持：</span>
                <span className="rdDescription">
                  可以使用遠程桌面軟件（例如TeamsViewer、Zoom等）共享桌面，並直接處理系統相關的問題或演示。
                </span>
              </div>

              <div className="rd05">
                <span className="starPoint">^^</span>
                <span className="rdTitle">遠程線上教學服務：</span>
                <span className="rdDescription">
                  選用Teams、Plus、Platform計劃，可以獲得體驗「遠程線上教學服務」一次（可選用Microsoft
                  Teams、Zoom等）。 通過共享桌面，進行相關系統教學演示2小時。
                </span>
              </div>
            </RemarkDescription>
          </RemarkWrapper>
        </ContentWrapper>
      </PricingWrapper>
      <CTASection
        title={
          <>
            馬上行動!
            <br className="visible-sm" /> 提升您診所的效率
          </>
        }
        subtitle={
          <>
            立即採用
            <br className="visible-sm" /> CareBridge！
          </>
        }
        description="立即預約 CareBridge 線上演示，了解如何透過智能化的診所管理系統提高工作效率，提供更好的服務。限時優惠，機會難得！"
        bootboxRef={bootboxRef}
      />
    </>
  );
};

export default PricingPage;

const PricingWrapper = styled.div`
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 144px 64px 112px;

  @media (max-width: 768px) {
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 76px 32px 32px;
  }
  @media (max-width: 375px) {
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 76px 16px 16px;
  }
`;

const ContentWrapper = styled.div`
  /* Auto layout */

  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  padding: 0px;
  gap: 48px;

  @media (max-width: 768px) {
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-itmes: center;
    padding: 0px;
    gap: 32px;
  }
  @media (max-width: 375px) {
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 32px;
  }
`;

const TitleWrapper = styled.div`
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 0px;
  }
  @media (max-width: 375px) {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 0px;
  }
`;

const Title = styled.div`
  /* Desktop/Heading/H1 (80) */
  style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 140%;

  /* Color Application/Button Colors/CareBridge Purple */
  color: #58457e;

  @media (max-width: 768px) {
    /* Mobile/Heading/H2(52) */

    font-family: "Noto Sans TC";
    font-sytle: normal;
    font-weight: 700;
    font-size: 52px;
    line-height: 140%;
  }
  @media (max-width: 375px) {
    /* Mobile/Heading/H2(52) */

    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 140%;
  }
`;

const Description = styled.div`
  /* Desktop/Regular/Regular (20) */
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  text-align: center;

  @media (max-width: 768px) {
    text-align: left;
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 160%;
  }

  @media (max-width: 375px) {
    text-align: left;
    /* Mobile/Small/Text(13) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 160%;
  }
  /* Color Application/Text Colors/Dark Blue */
  color: #2c497f;
`;

/* const ToggleButtonWrapper = MuiStyled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",

  '& button[value="yearly"]': {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px 24px",
    gap: "8px",
    background: "#B400AC",
    border: "2px solid #E2DCE5",
    borderRadius: "24px",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: 1.3,
    color: "#FFFFFF",
    "& .discount": {
      justifyContent: "center",
      alignItems: "center",
      padding: "4px 6px 4px 4px",
      background: "#C6D0EB",
      borderRadius: "10px",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "11px",
      lineHeight: "16px",
      color: "#4D3F6E",
    },
  },
  //gap: "32px",
  '& button[value="monthly"]': {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "12px 24px",
    background: "#EBEFFE",
    border: "2px solid #E2DCE5",
    borderRadius: "24px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: 1.6,
    color: "rgba(44, 73, 127, 0.5)",
  },
})); */

const PriceCardGroupWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;

  @media (max-width: 1234px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 32px;
  }

  @media (max-width: 768px) {
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 16px;
  }

  @media (max-width: 375px) {
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 16px;
  }

  &,
  * {
    .bigStarPoint {
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 140%;

      /* Color Application/Button Colors/Pink Red */
      color: #b400ac;

      @media (max-width: 768px) {
        font-size: 34px;
      }
      @media (max-width: 375px) {
        font-size: 34px;
      }
    }
    .starPoint {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 160%;

      /* Color Application/Special Color/Special Red */
      color: #e85d75;
    }

    .pricing {
      /* Auto layout */
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      padding: 0px;
      gap: 8px;

      font-family: "Noto Sans TC";
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 140%;

      /* or 67px */
      display: flex;
      align-items: center;

      /* Color Application/Text Colors/Dark Blue */
      color: #2c497f;

      @media (max-width: 375px) {
        font-family: "Noto Sans TC";
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        line-height: 140%;
      }
    }
    .month {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 130%;
      /* or 31px */

      display: flex;
      align-items: center;

      /* Color Application/Text Colors/Dark Blue */

      color: #2c497f;

      opacity: 0.8;

      @media (max-width: 375px) {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 130%;
      }
    }

    .intro {
      /* Auto layout */
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;

      /* Desktop/Normal/Text (16) */
      font-family: "Noto Sans TC";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 160%;

      /* or 26px */
      display: flex;
      align-items: center;

      /* Color Application/Text Colors/Dark Blue */
      color: #2c497f;

      opacity: 0.8;

      @media (max-width: 375px) {
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 160%;
      }
    }
  }
`;

// const Card01Wrapper = styled.div`
//   box-sizing: border-box;
//   /* Auto layout */
//   display: flex;
//   flex-direction: column;
//   place-content: space-between;

//   width: 100%;
//   /* align-items: center; */
//   padding: 0px 0px 24px;
//   gap: 32px;

//   /* width: 310px; */
//   /* height: 877px; */

//   border: 2px solid #58457e;
//   border-radius: 30px;

//   overflow: hidden;
// `;

//const Card01Title = styled.div`
//  /* Auto layout */
//  display: flex;
//  flex-direction: row;
//  justify-content: center;
//  align-items: center;
//  padding: 16px 32px;
//  gap: 12px;

//  /* width: 100%; */
//  //height: 70px;

//  /* Color Application/Gradients/Light Purple Red */
//  background: linear-gradient(180deg, #e9e5f1 0%, #f0d9ef 100%);

//  /* Color Application/BG Color/Primary Dark */
//  border-bottom: 2px dashed #4d3f6e;

//  /* Desktop/Large/Bold (24) */

//  font-family: "Noto Sans TC";
//  font-style: normal;
//  font-weight: 700;
//  font-size: 24px;
//  line-height: 130%;
//  /* or 31px */

//  display: flex;
//  align-items: center;
//  text-align: center;

//  /* Color Application/Text Colors/Dark Blue */

//  color: #2c497f;

//  @media (max-width: 375px) {
//    /* Mobile/Heading/H4 (24) */

//    font-family: "Noto Sans TC";
//    font-style: normal;
//    font-weight: 700;
//    font-size: 24px;
//    line-height: 130%;
//  }
//`;

// const Card01Price = styled.div`
//   box-sizing: border-box;
//   /* Auto layout */

//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   padding: 0px 28px 48px 28px;
//   gap: 16px;
//   /* height: 241px; */

//   border-bottom: 2px dashed rgba(77, 63, 110, 0.3);

//   @media (max-width: 375px) {
//     box-sizing: border-box;

//     /* Auto layout */
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     padding: 0px 28px 28px 28px;
//     gap: 16px;
//   }
// `;

// const Card01Description = styled.div`
//   /* Auto layout */

//   display: flex;
//   flex-direction: column;
//   place-items: start;
//   padding: 0px 28px;
//   gap: 4px;

//   .d01 {
//     /* Auto layout */

//     display: flex;
//     flex-direction: row;
//     /* align-items: center; */
//     padding: 0px;
//     gap: 8px;

//     /* Desktop/Regular/Regular (20) */
//     font-family: "Noto Sans TC";
//     font-style: normal;
//     font-weight: 700;
//     font-size: 20px;
//     line-height: 160%;

//     /* identical to box height, or 32px */
//     display: flex;
//     align-items: center;

//     /* Color Application/Text Colors/Dark Blue */
//     color: #2c497f;

//     .num {
//       font-style: normal;
//       font-weight: 700;
//       font-size: 20px;
//       line-height: 130%;
//       color: #b400ac;
//     }
//     @media (max-width: 375px) {
//       font-style: normal;
//       font-weight: 700;
//       font-size: 17px;
//       line-height: 160%;
//     }
//   }
//   .d02 {
//     /* Auto layout */

//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     padding: 0px;
//     gap: 8px;

//     /* Desktop/Regular/Regular (20) */
//     font-family: "Noto Sans TC";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 20px;
//     line-height: 160%;

//     /* identical to box height, or 32px */
//     display: flex;
//     align-items: center;

//     /* Color Application/Text Colors/Dark Blue */
//     color: #2c497f;

//     @media (max-width: 375px) {
//       font-style: normal;
//       font-weight: 400;
//       font-size: 17px;
//       line-height: 160%;
//     }
//   }
//   .d03 {
//     /* Auto layout */

//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     padding: 0px;
//     gap: 8px;

//     /* Desktop/Regular/Regular (20) */
//     font-family: "Noto Sans TC";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 20px;
//     line-height: 160%;

//     /* identical to box height, or 32px */
//     display: flex;
//     align-items: center;

//     /* Color Application/Text Colors/Dark Blue */
//     color: #2c497f;

//     @media (max-width: 375px) {
//       font-style: normal;
//       font-weight: 400;
//       font-size: 17px;
//       line-height: 160%;
//     }
//   }
//   .d04 {
//     /* Auto layout */

//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     padding: 0px;
//     gap: 8px;

//     /* Desktop/Regular/Regular (20) */
//     font-family: "Noto Sans TC";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 20px;
//     line-height: 160%;

//     /* identical to box height, or 32px */
//     display: flex;
//     align-items: center;

//     /* Color Application/Text Colors/Dark Blue */
//     color: #2c497f;

//     @media (max-width: 375px) {
//       font-style: normal;
//       font-weight: 400;
//       font-size: 17px;
//       line-height: 160%;
//     }
//   }
//   .d05 {
//     /* Auto layout */

//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     padding: 0px;
//     gap: 8px;

//     /* Desktop/Regular/Regular (20) */
//     font-family: "Noto Sans TC";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 20px;
//     line-height: 160%;

//     /* identical to box height, or 32px */
//     display: flex;
//     align-items: center;

//     /* Color Application/Text Colors/Dark Blue */
//     color: #2c497f;

//     @media (max-width: 375px) {
//       font-style: normal;
//       font-weight: 400;
//       font-size: 17px;
//       line-height: 160%;
//     }
//   }
//   .d06 {
//     /* Auto layout */

//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     padding: 0px;
//     gap: 8px;

//     /* Desktop/Regular/Regular (20) */
//     font-family: "Noto Sans TC";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 20px;
//     line-height: 160%;

//     /* identical to box height, or 32px */
//     display: flex;
//     align-items: center;

//     /* Color Application/Text Colors/Dark Blue */
//     color: #2c497f;

//     @media (max-width: 375px) {
//       font-style: normal;
//       font-weight: 400;
//       font-size: 17px;
//       line-height: 160%;
//     }
//   }
//   .d07 {
//     /* Auto layout */

//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     padding: 0px;
//     gap: 8px;

//     /* Desktop/Regular/Regular (20) */
//     font-family: "Noto Sans TC";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 20px;
//     line-height: 160%;

//     /* identical to box height, or 32px */
//     display: flex;
//     align-items: center;

//     /* Color Application/Text Colors/Dark Blue */
//     color: #2c497f;

//     @media (max-width: 375px) {
//       font-style: normal;
//       font-weight: 400;
//       font-size: 17px;
//       line-height: 160%;
//     }
//   }
//   .d08 {
//     /* Auto layout */

//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     padding: 0px;
//     gap: 8px;

//     /* Desktop/Regular/Regular (20) */
//     font-family: "Noto Sans TC";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 20px;
//     line-height: 160%;

//     /* identical to box height, or 32px */
//     display: flex;
//     align-items: center;

//     /* Color Application/Text Colors/Dark Blue */
//     color: #2c497f;

//     @media (max-width: 375px) {
//       font-style: normal;
//       font-weight: 400;
//       font-size: 17px;
//       line-height: 160%;
//     }
//   }
//   .d09 {
//     /* Auto layout */

//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     padding: 0px;
//     gap: 8px;

//     /* Desktop/Regular/Regular (20) */
//     font-family: "Noto Sans TC";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 20px;
//     line-height: 160%;

//     /* identical to box height, or 32px */
//     display: flex;
//     align-items: center;

//     /* Color Application/Text Colors/Dark Blue */
//     color: #2c497f;

//     @media (max-width: 375px) {
//       font-style: normal;
//       font-weight: 400;
//       font-size: 17px;
//       line-height: 160%;
//     }
//   }
//   .d10 {
//     /* Auto layout */

//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     padding: 0px;
//     gap: 8px;

//     /* Desktop/Regular/Regular (20) */
//     font-family: "Noto Sans TC";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 20px;
//     line-height: 160%;

//     /* identical to box height, or 32px */
//     display: flex;
//     align-items: center;

//     /* Color Application/Text Colors/Dark Blue */
//     color: #2c497f;

//     @media (max-width: 375px) {
//       font-style: normal;
//       font-weight: 400;
//       font-size: 17px;
//       line-height: 160%;
//     }
//   }
// `;

const Card02Wrapper = styled.div`
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: column;
  place-content: space-between;

  width: 100%;
  /* align-items: center; */
  padding: 0px 0px 24px;
  gap: 32px;

  border: 2px solid #58457e;
  border-radius: 30px;

  overflow: hidden;
`;

const Card02Title = styled.div`
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 12px;

  /* width: 100%; */
  //height: 70px;

  /* Color Application/Gradients/Light Purple Red */
  background: linear-gradient(180deg, #e9e5f1 0%, #f0d9ef 100%);

  /* Color Application/BG Color/Primary Dark */
  border-bottom: 2px dashed #4d3f6e;

  /* Desktop/Large/Bold (24) */

  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  /* or 31px */
  .title-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    .title{
      font-size: 48px ;
     }
  }
   

  display: flex;
  align-items: center;
  text-align: center;

  /* Color Application/Text Colors/Dark Blue */

  color: #2c497f;
`;

const Card02Price = styled.div`
  /* Auto layout */

  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  align-items: baseline;
  padding: 0px 28px 48px 28px;
  gap: 16px;

  .pricing {
    display: flex;
    align-items: baseline;
  }
  /* height: 241px; */

  border-bottom: 2px dashed rgba(77, 63, 110, 0.3);
`;

const Card02Description = styled.div`
  /* Auto layout */

  display: flex;
  flex-direction: column;
  place-items: start;
  padding: 0px 28px;
  /* width: 100%; */
  /* height: 384px; */
  /* margin: 0px auto; */
  gap: 4px;

  .d01 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    /* align-items: center; */
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    .num {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 130%;
      color: #b400ac;
    }
    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 700;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d02 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d03 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }

  .d04 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }

  .d05 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d06 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d07 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d08 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d09 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d10 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d11 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d12 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
`;
const Card03Wrapper = styled.div`
  /* Auto layout */
  display: flex;
  flex-direction: column;
  place-content: space-between;

  width: 100%;
  /* align-items: center; */
  padding: 0px 0px 24px;
  gap: 32px;

  /* width: 310px; */
  /* height: 973px; */

  border: 2px solid #58457e;
  border-radius: 30px;

  overflow: hidden;
`;

const Card03Title = styled.div`
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 12px;
  /* width: 100%; */
  //height: 70px;
  
  .title-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    .title{
      font-size: 48px ;
     }
  }

  /* Color Application/Gradients/Pink Red Grey Purple */
  background: linear-gradient(180deg, #b400ac 0%, #bbacc1 100%);
  background-repeat: repeat-x;

  /* Color Application/BG Color/Primary Dark */
  border-bottom: 2px dashed #4d3f6e;

  /* Desktop/Large/Bold (24) */

  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  /* or 31px */

  display: flex;
  align-items: center;
  text-align: center;

  /* Color Application/Text Colors/White */
  color: #ffffff;
`;

const Card03Price = styled.div`
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 28px 48px 28px;
  gap: 16px;
  /* height: 241px; */
  .pricing {
    display: flex;
    align-items: baseline;
  }

  border-bottom: 2px dashed rgba(77, 63, 110, 0.3);
`;

const Card03Description = styled.div`
  /* Auto layout */

  display: flex;
  flex-direction: column;
  place-items: start;
  padding: 0px 28px;
  /* width: 100%; */
  /* height: 384px; */
  /* margin: 0px auto; */
  gap: 4px;

  .d01 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    /* align-items: center; */
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    .num {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 130%;
      color: #b400ac;
    }
    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 700;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d02 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d03 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d04 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d05 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d06 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d07 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d08 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d09 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d10 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d11 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d12 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
`;
const Card04Wrapper = styled.div`
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  place-content: space-between;

  width: 100%;
  /* align-items: center; */
  padding: 0px 0px 24px;
  gap: 32px;

  /* width: 310px; */
  /* height: 973px; */

  border: 2px solid #58457e;
  border-radius: 30px;

  overflow: hidden;
`;

const Card04Title = styled.div`
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 12px;
  background-repeat: repeat-x;
  .title-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    .title{
      font-size: 48px ;
     }
  }

  /* width: 100%; */
  //height: 70px;

  /* Color Application/Gradients/Pink Red Grey Purple */
  background: linear-gradient(180deg, #b400ac 0%, #bbacc1 100%);
  background-repeat: repeat-x;

  /* Color Application/BG Color/Primary Dark */
  border-bottom: 2px dashed #4d3f6e;

  /* Desktop/Large/Bold (24) */

  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  /* or 31px */

  display: flex;
  align-items: center;
  text-align: center;

  /* Color Application/Text Colors/White */
  color: #ffffff;
`;

const Card04Price = styled.div`
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 28px 48px 28px;
  gap: 16px;
  .pricing {
    display: flex;
    align-items: baseline;
  }

  border-bottom: 2px dashed rgba(77, 63, 110, 0.3);
`;

const Card04Description = styled.div`
  /* Auto layout */

  display: flex;
  flex-direction: column;
  place-items: start;
  padding: 0px 28px;
  /* width: 100%; */
  /* height: 384px; */
  /* margin: 0px auto; */
  gap: 4px;

  .d01 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    /* align-items: center; */
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    .num {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 130%;
      color: #b400ac;
    }

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 700;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d02 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d03 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d04 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d05 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d06 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d07 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d08 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d09 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }

    @media (max-width: 375px) {
    }
  }
  .d10 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d11 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
  .d12 {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Desktop/Regular/Regular (20) */
    font-family: "Noto Sans TC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;

    /* identical to box height, or 32px */
    display: flex;
    align-items: center;

    /* Color Application/Text Colors/Dark Blue */
    color: #2c497f;

    @media (max-width: 375px) {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
    }
  }
`;

const SurchargesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  *,
  & {
    .sdTitle {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 140%;

      /* Color Application/Text Colors/Dark Blue */
      color: #2c497f;

      @media (max-width: 375px) {
        font-size: 20px;
      }
    }
    .sdDescription {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 160%;
      /* or 32px */
      /* Color Application/Text Colors/Dark Blue */
      color: #2c497f;

      @media (max-width: 375px) {
        font-size: 17px;
      }
    }
  }
`;

const SurchargesTitle = styled.div`
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  /* identical to box height, or 34px */

  /* Color Application/Button Colors/CareBridge Purple */

  color: #58457e;
`;

const SurchargesDescription = styled.div``;

const RemarkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  *,
  & {
    .starPoint {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 140%;
      color: #e85d75;

      @media (max-width: 375px) {
        font-size: 20px;
      }
    }

    .rdTitle {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 140%;

      /* Color Application/Text Colors/Dark Blue */
      color: #2c497f;

      @media (max-width: 375px) {
        font-size: 20px;
      }
    }
    .rdDescription {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 160%;
      /* or 32px */
      /* Color Application/Text Colors/Dark Blue */
      color: #2c497f;

      @media (max-width: 375px) {
        font-size: 17px;
      }
    }
  }
`;

const RemarkTitle = styled.div`
  font-family: "Noto Sans TC";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  /* identical to box height, or 34px */

  /* Color Application/Button Colors/CareBridge Purple */

  color: #58457e;
`;

const RemarkDescription = styled.div``;
