import { useMediaQuery, useTheme } from "@mui/material";

const useVariants = (choice) => {
  const theme = useTheme();

  const breakpoints = {
    isXs: useMediaQuery(theme.breakpoints.up("xs")),
    isSm: useMediaQuery(theme.breakpoints.up("sm")),
    isMd: useMediaQuery(theme.breakpoints.up("md")),
    isLg: useMediaQuery(theme.breakpoints.up("lg")),
    active: "xs",
  };
  if (breakpoints.isXs) breakpoints.active = "xs";
  if (breakpoints.isSm) breakpoints.active = "sm";
  if (breakpoints.isMd) breakpoints.active = "md";
  if (breakpoints.isLg) breakpoints.active = "lg";

  // check choice if exist
  if (!choice) return;
  let variant = null;

  if (breakpoints.isXs && choice?.xs) variant = choice.xs;
  if (breakpoints.isSm && choice?.sm) variant = choice.sm;
  if (breakpoints.isMd && choice?.md) variant = choice.md;
  if (breakpoints.isLg && choice?.lg) variant = choice.lg;

  if (!variant && breakpoints.active === "xs" && choice?.sm)
    variant = choice.sm;
  if (!variant && breakpoints.active === "xs" && choice?.md)
    variant = choice.md;
  if (!variant && breakpoints.active === "xs" && choice?.lg)
    variant = choice.lg;

  if (!variant && breakpoints.active === "sm" && choice?.md)
    variant = choice.md;
  if (!variant && breakpoints.active === "sm" && choice?.lg)
    variant = choice.lg;

  if (!variant && breakpoints.active === "md" && choice?.lg)
    variant = choice.lg;

  return variant;
};

export default useVariants;
