import { useState } from "react";
import { styled } from "@mui/system";
import { Box } from "@mui/material";

import TopNavbar from "../sections/TopNavbar";
import FooterSection from "../sections/FooterSection";

const HomepageWrapper = styled(Box)(({ theme }) => ({
  height: "100%",
  "& .hidden-md": {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  "& .visible-md": {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  "& .hidden-sm": {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  "& .visible-sm": {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  "& .hidden-xs": {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  "& .visible-xs": {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const MainWrapper = styled(Box)(({ theme }) => ({
  paddingTop: 112,
  [theme.breakpoints.down("sm")]: {
    paddingTop: 80,
  },
}));

const HomeLayout = ({ children }) => {
  const [pages] = useState([
    { path: "/#problem", name: "痛點" },
    { path: "/#solution", name: "解決方案" },
    { path: "/#feature", name: "特色功能" },
    { path: "/#why", name: "為什麼選擇CareBridge?" },
    { path: "/#faq", name: "FAQ" },
    { path: "/pricing", name: "方案費用" },
    { path: "/resources", name: "資源站" },
    { path: "/inquiry-partner", name: "商業合作" },
    // { path: "/free-program", name: "免费程序" },
  ]);

  return (
    <HomepageWrapper>
      <TopNavbar pages={pages} />
      <MainWrapper>{children}</MainWrapper>
      <FooterSection pages={pages} />
    </HomepageWrapper>
  );
};
export default HomeLayout;
