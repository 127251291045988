import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import {
  Container,
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";

import BigTitle from "./BigTitle";
import MyButton from "../tools/MyButton";

const FAQWrapper = styled(Box)(({ theme }) => ({
  paddingTop: 112,
  paddingBottom: 112,
  [theme.breakpoints.down("md")]: {
    paddingTop: 64,
    paddingBottom: 64,
  },
  [theme.breakpoints.down("sm")]: {
    paddingTop: 36,
    paddingBottom: 36,
  },

  "& h1": {
    marginLeft: "0!important",
    marginRight: "0!important",
    [theme.breakpoints.down("md")]: {
      fontSize: "52px!important",
    },
  },
  "& h3": {
    color: "#2C497F",
    [theme.breakpoints.down("md")]: {
      fontSize: "24px!important",
    },
  },
  "& .MuiTypography-normal": {
    color: "#2C497F",
    paddingTop: 16,
    paddingBottom: 16,
  },

  "& .btn-container": {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    "& >div": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
        "& .MuiButtonBase-root": {
          width: "100%",
        },
      },
      "&:not(:last-child)": {
        [theme.breakpoints.up("md")]: {
          marginRight: 20,
        },
        [theme.breakpoints.down("md")]: {
          marginBottom: 12,
        },
      },
    },
  },
  "& [name=red-button]": {
    "& .MuiButtonBase-root": {
      [theme.breakpoints.up("md")]: {
        padding: "30px 64px",
      },
    },
  },

  "& .question-container": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 94,
    paddingRight: 94,
    [theme.breakpoints.down("md")]: {
      paddingTop: 32,
      paddingBottom: 32,
      paddingLeft: 0,
      paddingRight: 0,
    },

    "& .MuiAccordion-root": {
      background: "unset",
      boxShadow: "unset",
      position: "unset",
      marginBottom: 12,
      width: "100%",

      "&.Mui-expanded": {
        margin: 0,
      },

      "& .MuiAccordionSummary-root": {
        color: "#2C497F",
        borderRadius: 30,
        background: "linear-gradient(180deg, #E9E5F1 0%, #F0D9EF 100%)",
        border: "1px solid #58457E",
        padding: "12px 24px",
        "& .MuiAccordionSummary-content": {
          margin: 0,
        },
        "& .MuiAccordionSummary-expandIconWrapper": {
          color: "#b400AC",
          "&.Mui-expanded": {
            transform: "rotate(45deg)",
          },
        },
      },
      "& .MuiAccordionDetails-root": {
        paddingTop: 24,
        paddingBottom: 24,
      },
    },
  },
}));

const FAQSection = ({ title, description, questions = [] }) => {
  const navigate = useNavigate();
  const handleGoMeeting = () => {
    window.open("https://meetings.hubspot.com/david2085");
  };
  return (
    <Container maxWidth="lg">
      <FAQWrapper id="faq">
        <Grid container>
          <Grid item sm={12} md={5}>
            <BigTitle title={title} align="left" container={false}>
              {description}
            </BigTitle>
            <Box className="btn-container">
              <MyButton
                name="red-button"
                onClick={() => {
                  navigate("/#TrialFormSection");
                }}
              >
                申請限時體驗名額
              </MyButton>
              <MyButton name="sales-button" onClick={handleGoMeeting}>
                聯絡銷售
              </MyButton>
            </Box>
          </Grid>
          <Grid item sm={12} md={7} className="question-container">
            {questions.map((question, i) => (
              <Accordion key={i}>
                <AccordionSummary
                  expandIcon={<AddIcon />}
                  aria-controls={`panel${i}-content`}
                >
                  <Typography variant="caption">{question.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="normal">{question.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Grid>
      </FAQWrapper>
    </Container>
  );
};

export default FAQSection;
