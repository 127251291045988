import { ReactComponent as FeedbackGraphic } from "../../media/img/AI/feedback.svg";
import { ReactComponent as HandGraphic } from "../../media/img/AI/hand.svg";
import { ReactComponent as LockedGraphic } from "../../media/img/AI/locked.svg";
import { ReactComponent as PhoneGraphic } from "../../media/img/AI/phone.svg";
import { ReactComponent as TogetherGraphic } from "../../media/img/AI/Together.svg";
import { ReactComponent as UiDesignGraphic } from "../../media/img/AI/ui-design.svg";
import { ReactComponent as UiDesign2Graphic } from "../../media/img/AI/ui-design2.svg";
import { ReactComponent as WebsiteDesignGraphic } from "../../media/img/AI/website-design.svg";

const Graphic = ({ name }) => {
  return (
    <>
      {name === "feedback" && <FeedbackGraphic />}
      {name === "hand" && <HandGraphic />}
      {name === "locked" && <LockedGraphic />}
      {name === "phone" && <PhoneGraphic />}
      {name === "together" && <TogetherGraphic />}
      {name === "ui-design" && <UiDesignGraphic />}
      {name === "ui-design2" && <UiDesign2Graphic />}
      {name === "website-design" && <WebsiteDesignGraphic />}
    </>
  );
};
export default Graphic;
