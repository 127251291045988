import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Container,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Link,
  Toolbar,
} from "@mui/material";
import { styled } from "@mui/system";
import { Menu as MenuIcon } from "@mui/icons-material";
import { ReactComponent as CareBridgeLogo } from "../../media/svg/logo/carebridge-logo.svg";
import { ReactComponent as CareBridgeSmallLogo } from "../../media/svg/logo/carebridge-logo-s.svg";

// import my component
import MyButton from "../tools/MyButton";

const TopNavbarWrapper = styled(Box, {
  name: "TopNavbarWrapper",
})(({ theme }) => ({
  "& .MuiPaper-root": {
    display: "flex",
    justifyContent: "center",
  },

  "& .MuiAppBar-root": {
    height: 112,
    [theme.breakpoints.down("sm")]: {
      height: 80,
    },
    background: "#f8f9fa",
    color: "#2c497f",
    boxShadow: "unset",

    "& .MuiToolbar-root": {
      display: "flex",
      position: "relative",
      justifyContent: "center",

      "& .logo": {
        position: "absolute",
        left: 0,
        top: 0,
        [theme.breakpoints.down("md")]: {
          top: 6,
        },
      },

      "& .menuContainer": {
        border: "2px solid #58457E",
        borderRadius: 32,
        display: "flex",
        padding: "6px 16px",
        justifyContent: "space-between",
        gap: 32,

        "& .MuiButton-root": {
          color: "#2c497f",
          fontWeight: 400,
          fontSize: 16,
          lineHeight: 1.6,
          padding: "6px 16px",
          margin: 0,
          transition: "0.3s cubic-bezier(0.4, 0, 0.2, 1)",
          [theme.breakpoints.up("md")]: {
            "&:hover": {
              /* Button/ text button */
              boxSizing: "border-box",

              /* Auto layout */

              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "6px 16px",

              background: "#F8F9FA",
              border: "4px solid #B400AC",
              borderRadius: 30,

              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "18px",
              lineHeight: 1.6,

              color: "#2C497F",
            },
          },
        },
      },
    },
  },
}));

const TopNavbar = ({ pages = [], ...rest }) => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (e) => {
    setAnchorElNav(e.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <TopNavbarWrapper>
      <AppBar position="fixed">
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Link href="/" className="visible-md">
              <CareBridgeLogo className="logo " />
            </Link>
            <Link href="/" className="hidden-md">
              <CareBridgeSmallLogo className="logo " />
            </Link>
            {/* for mobile menu*/}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", sm: "flex", md: "none" },
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { sm: "block", md: "none" },
                }}
              >
                {pages.map((page, i) => (
                  <MenuItem
                    key={i}
                    onClick={() => {
                      navigate(page.path);
                      handleCloseNavMenu();
                    }}
                  >
                    <Typography textAlign="center">{page.name}</Typography>
                  </MenuItem>
                ))}
                <Box
                  sx={{
                    flex: "1 1 auto",
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "flex-end",
                    padding: 1,
                  }}
                >
                  <Typography variant="normal" sx={{ textAlign: "center" }}>
                    是否現有客戶？
                    <Link
                      underline="none"
                      href="http://carebridge.marstree.ltd"
                      target="_blank"
                      variant="link"
                    >
                      登入
                    </Link>
                  </Typography>

                  <MyButton
                    name="red-button"
                    fullWidth
                    onClick={() => {
                      handleCloseNavMenu();
                      navigate("/#TrialFormSection");
                    }}
                  >
                    申請限時體驗名額
                  </MyButton>
                </Box>
              </Menu>
            </Box>
            {/* for desktop menu */}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
              }}
            >
              <Box className="menuContainer">
                {pages.map((page, i) => (
                  <Button
                    key={i}
                    onClick={() => {
                      navigate(page.path);
                      handleCloseNavMenu();
                    }}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    {page.name}
                  </Button>
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                flexGrow: 0,
                display: { xs: "none", md: "flex" },
                position: "absolute",
                right: 0,
              }}
            >
              <MyButton
                name="red-button"
                onClick={() => window.open("https://carebridge.marstree.ltd")}
              >
                登入
              </MyButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </TopNavbarWrapper>
  );
};

export default TopNavbar;
