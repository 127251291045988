import { Container, Grid, Typography, Box } from "@mui/material";
import { styled } from "@mui/system";
import useVariants from "../../helpers/useVariants";

const Wrapper = styled(Box)(({ theme }) => ({
  paddingTop: 112,
  paddingBottom: 112,
  [theme.breakpoints.down("md")]: {
    paddingTop: 64,
    paddingBottom: 64,
  },

  "& .photo": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& img": {
      width: "100%",
      objectFit: "contain",
      filter: "drop-shadow(0px 30px 60px rgba(0, 0, 0, 0.25))",
      [theme.breakpoints.down("md")]: {
        maxHeight: 550,
      },
    },
  },

  "& .article": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#2C497F",
    [theme.breakpoints.up("md")]: {
      paddingLeft: 80,
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: 40,
    },

    "& .title": {
      marginBottom: 48,
    },

    "& .caption-container": {
      display: "flex",
      flexDirection: "column",
      "& .caption": {
        marginBottom: 32,
        display: "flex",
        flexDirection: "column",
      },
    },
  },
}));

const WhySection = ({ photo, title, description, list = [] }) => {
  const variants = useVariants({
    md: "large-bold",
    sm: "body-intro",
  });
  const variantDesc = useVariants({
    md: "caption",
    sm: "body-main",
  });

  return (
    <Container maxWidth="lg">
      <Wrapper id="why">
        <Grid container>
          <Grid item sm={12} md={5} className="photo">
            <img src={photo} alt="CareBridge 診所管理系統" />
          </Grid>
          <Grid item sm={12} md={7} className="article">
            <Typography variant="h4" className="title">
              {title}
            </Typography>
            {description && (
              <Typography variant="normal" className="description">
                {description}
              </Typography>
            )}
            <Box className="caption-container">
              {list.map((row, i) => (
                <Box className="caption" key={i}>
                  <Typography variant={variants} className="caption-title">
                    {row.caption}
                  </Typography>

                  <Typography variant={variantDesc} className="caption-desc">
                    {row.description}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Wrapper>
    </Container>
  );
};
export default WhySection;
