import { useState } from "react";
import { styled } from "@mui/system";
import { Container, Box, Tabs, Tab, useTheme } from "@mui/material";
import SwipeableViews from "react-swipeable-views";

import TabPanel from "../tools/TabPanel";
import BigTitle from "./BigTitle";

const MySlideSectionWrapper = styled(Box)(({ theme }) => ({
  paddingBottom: 112,
  [theme.breakpoints.down("sm")]: {
    paddingBottom: 0,
  },
  "& .MuiTabs-root": {
    margin: 32,
    [theme.breakpoints.down("sm")]: {
      margin: "40px 0 24px 0",
    },
    "& .MuiTabs-scroller": {
      overflowX: "auto!important",
      /* width */
      "&::-webkit-scrollbar": {
        width: 5,
      },
      /* Track */
      "&::-webkit-scrollbar-track": {
        borderRadius: 10,
      },

      /* Handle */
      "&::-webkit-scrollbar-thumb": {
        borderRadius: 10,
      },

      [theme.breakpoints.up("sm")]: {
        "& .MuiTabs-flexContainer": {
          justifyContent: "center",
        },
      },
      [theme.breakpoints.down("sm")]: {
        "& .MuiTabs-flexContainer": {
          flexWrap: "wrap",
          gap: 16,
        },
      },
    },

    "& .MuiTab-root": {
      color: "#58457E",
      border: "2px dashed #58457E",
      borderRadius: 30,
      fontSize: 30,
      padding: "12px 64px",
      marginLeft: 32,

      [theme.breakpoints.down("md")]: {
        fontSize: 24,
        padding: "12px 56px",
        marginLeft: 16,
      },
      [theme.breakpoints.down("sm")]: {
        padding: 12,
        marginLeft: 0,
        width: "calc(50% - 16px)",
        fontWeight: 700,
      },

      "&.Mui-selected": {
        background: "#B400AC",
        borderColor: "#B400AC",
        borderStyle: "solid",
        color: "#FFF",
        "&.not-ready": {
          background: "#BBACC1",
          borderColor: "#BBACC1",
        },
      },
    },

    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
}));

const MySlideSection = ({ title, description, list = [] }) => {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <MySlideSectionWrapper id="feature">
      <BigTitle title={title} description={description} />

      <Container maxWidth="lg">
        <Tabs value={value} onChange={handleChange} scrollButtons={true}>
          {list.map((row, i) => (
            <Tab
              className={value === i && row.subtitle ? "not-ready" : ""}
              label={value === i && row.subtitle ? row.subtitle : row.title}
              key={i}
            />
          ))}
        </Tabs>
        <SwipeableViews
          axis="x"
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          {list.map((row, i) => (
            <TabPanel
              value={value}
              index={i}
              key={i}
              dir={theme.direction}
              row={row}
            />
          ))}
        </SwipeableViews>
      </Container>
    </MySlideSectionWrapper>
  );
};

export default MySlideSection;
