import { useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";

import { styled } from "@mui/system";

import CTASection from "../components/sections/CTASection";
import Bootbox from "../components/tools/Bootbox";

import BlogContentSection from "../components/sections/BlogContentSection";
import BlogListSection from "../components/sections/BlogListSection";

const Wrapper = styled(Box)(({ theme }) => ({
  "& .MuiTypography-h2.rel-article": {
    color: "#58457E",
    paddingTop: 80,
    paddingBottom: 80,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      paddingTop: 56,
      paddingBottom: 56,
    },
  },
}));

const BlogDetailPage = () => {
  const bootboxRef = useRef();
  const { slug } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <Wrapper>
      <Bootbox title="提交" ref={bootboxRef}></Bootbox>
      <BlogContentSection slug={slug}>
        <Container maxWidth="lg">
          <Typography variant="h2" className="rel-article">
            相關文章
          </Typography>
          <BlogListSection per_page={2} isShowMore={false} isRel={true} />
        </Container>
      </BlogContentSection>
      <CTASection
        title={
          <>
            馬上行動!
            <br className="visible-sm" /> 提升您診所的效率
          </>
        }
        subtitle={
          <>
            立即採用
            <br className="visible-sm" /> CareBridge！
          </>
        }
        description="立即預約 CareBridge 線上演示，了解如何透過智能化的診所管理系統提高工作效率，提供更好的服務。限時優惠，機會難得！"
        bootboxRef={bootboxRef}
      />
    </Wrapper>
  );
};

export default BlogDetailPage;
