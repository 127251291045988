import { useEffect, useState, useCallback } from "react";
import qs from "qs";
import { useLocation } from "react-router-dom";
//import MuiPhoneNumber from "material-ui-phone-number";
import { Box, FormControl, InputLabel } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";

import MyButton from "../tools/MyButton";
import MyCountDown from "../tools/MyCountDown";
import BootboxAlertContent from "./BootboxAlertContent";

import BootstrapInput from "./BootstrapInput";
import useDeal from "../../helpers/useDeal";
import * as RegisterModel from "../../models/Register";

const TrialForm = ({ bootboxRef }) => {
  const location = useLocation();
  //eslint-disable-next-line
  const deal = useDeal();
  const dealInfo = deal.getDeal();
  const [initialValues, setInitialValues] = useState(dealInfo);

  const handleGoMeeting = (e) => {
    e.preventDefault();
    window.open("https://meetings.hubspot.com/david2085");
  };

  const onSubmit = useCallback(
    async (values, { setStatus, setSubmitting, setErrors }) => {
      setSubmitting(true);
      if (!values?.email) {
        bootboxRef?.current?.content(
          <BootboxAlertContent
            message="提交錯誤！請重新嘗試"
            bootboxRef={bootboxRef}
          />,
        );
        bootboxRef?.current?.show();
        setSubmitting(true);
        return false;
      }
      try {
        const resp = await RegisterModel.submit(values);
        if (!resp) {
          bootboxRef?.current?.content(
            <BootboxAlertContent
              message="提交錯誤！這個電子信箱已經被註冊！"
              bootboxRef={bootboxRef}
            />,
          );
          bootboxRef?.current?.show();
          setSubmitting(true);
          window.location.href = "https://carebridge.marstree.ltd";
          return false;
        } else {
          await deal.submitDeal(values);
          bootboxRef?.current?.content(
            <BootboxAlertContent
              message="完成提交！感謝您的支持。"
              bootboxRef={bootboxRef}
            />,
          );
          bootboxRef?.current?.show();
          window.location.href = "https://carebridge.marstree.ltd";
        }
      } catch (error) {
        bootboxRef?.current?.content(
          <BootboxAlertContent
            message="完成提交！感謝您的支持。"
            bootboxRef={bootboxRef}
          />,
        );
        bootboxRef?.current?.show();
        console.log("error: ", error);
      } finally {
        setSubmitting(false);
      }
    },
    [bootboxRef, deal],
  );

  useEffect(() => {
    const email = qs.parse(location.search, { ignoreQueryPrefix: true }).email;
    setInitialValues({ ...initialValues, email: email });
    // eslint-disable-next-line
  }, [location]);

  return (
    <Formik
      autoComplete="off"
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        email: Yup.string().required("電郵地址為必填"),
        firstname: Yup.string().required("名字為必填"),
        phone: Yup.string().required("電話為必填"),
        companyName: Yup.string().required("公司名稱為必填"),
        password: Yup.string()
          .required("Required")
          .matches(
            /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
            "必需為數字、大小寫英文字母及最少也個字符",
          ),
        confirm_password: Yup.string()
          .required("Required")
          .oneOf([Yup.ref("password")], "密碼要一樣"),
      })}
      onSubmit={onSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        status,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box className="TrialForm">
            <MyCountDown
              title="不容錯過的限時體驗，現在就申請註冊！"
              time={15 * 60 * 1000}
            />
            <FormControl fullWidth>
              <InputLabel shrink required>
                您的名稱:
              </InputLabel>
              <BootstrapInput
                fullWidth
                required
                placeholder="輸入名稱"
                error={Boolean(touched.firstname && errors.firstname)}
                onBlur={handleBlur}
                onChange={handleChange}
                name="firstname"
                type="text"
                value={values?.firstname || ""}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel shrink required>
                您的地區:
              </InputLabel>
              <BootstrapInput
                fullWidth
                required
                placeholder="輸入地區"
                error={Boolean(touched.region && errors.region)}
                onBlur={handleBlur}
                onChange={handleChange}
                name="region"
                type="text"
                value={values?.region || ""}
                inputProps={{
                  autoComplete: "new-password",
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel shrink>銷售代理編號:</InputLabel>
              <BootstrapInput
                fullWidth
                placeholder="輸入銷售代理編號"
                error={Boolean(touched.salesCode && errors.salesCode)}
                onBlur={handleBlur}
                onChange={handleChange}
                name="salesCode"
                type="text"
                value={values?.salesCode || ""}
                inputProps={{
                  autoComplete: "new-password",
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel shrink required>
                您的電話:
              </InputLabel>
              <BootstrapInput
                fullWidth
                required
                placeholder="輸入電話"
                error={Boolean(touched.phone && errors.phone)}
                onBlur={handleBlur}
                onChange={handleChange}
                name="phone"
                type="text"
                value={values?.phone || ""}
                inputProps={{
                  autoComplete: "new-password",
                }}
              />

              {/*
              <MuiPhoneNumber
                className="MuiPhoneNumber"
                required
                name="phone"
                error={Boolean(touched.phone && errors.phone)}
                fullWidth
                helperText={touched.phone && errors.phone}
                margin="normal"
                onBlur={handleBlur}
                onChange={(value) => setFieldValue("phone", value)}
                value={values?.phone || ""}
                preferredCountries={["mo", "hk", "cn"]}
                onlyCountries={["mo", "hk", "cn", "sg", "my", "tw"]}
                defaultCountry="mo"
                autoComplete="off"
              />
              */}
            </FormControl>
            <FormControl fullWidth>
              <InputLabel shrink required>
                您的公司名稱:
              </InputLabel>
              <BootstrapInput
                fullWidth
                required
                placeholder="輸入公司名稱"
                error={Boolean(touched.companyName && errors.companyName)}
                onBlur={handleBlur}
                onChange={handleChange}
                name="companyName"
                type="text"
                value={values?.companyName || ""}
                inputProps={{
                  autoComplete: "new-password",
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel shrink required>
                您的電郵地址:
              </InputLabel>
              <BootstrapInput
                fullWidth
                required
                placeholder="輸入電郵地址"
                error={Boolean(touched.email && errors.email)}
                onBlur={handleBlur}
                onChange={handleChange}
                name="email"
                type="text"
                value={values?.email || ""}
                inputProps={{
                  autoComplete: "new-password",
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel shrink required>
                創建密碼
              </InputLabel>
              <BootstrapInput
                fullWidth
                required
                error={Boolean(touched.password && errors.password)}
                onBlur={handleBlur}
                onChange={handleChange}
                name="password"
                type="password"
                autoComplete="new-password"
                value={values?.password || ""}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel shrink required>
                確認密碼
              </InputLabel>
              <BootstrapInput
                fullWidth
                required
                error={Boolean(
                  touched.confirm_password && errors.confirm_password,
                )}
                onBlur={handleBlur}
                onChange={handleChange}
                name="confirm_password"
                type="password"
                autoComplete="new-password"
                value={values?.confirm_password || ""}
              />
            </FormControl>
            {/*<FormControl fullWidth>
              <InputLabel shrink required>
                輸入驗證碼
              </InputLabel>
              <BootstrapInput
                fullWidth
                required
                placeholder="驗證碼"
                error={Boolean(touched.vcode && errors.vcode)}
                onBlur={handleBlur}
                onChange={handleChange}
                name="vcode"
                type="vcode"
                value={values?.vcode || ""}
              />
            </FormControl>*/}
          </Box>
          <Box className="footer">
            <MyButton
              type="reset"
              name="form-button"
              variant="outlined"
              color="secondary"
            >
              重新輸入
            </MyButton>
            <Box className="btn-group">
              <MyButton
                name="form-button"
                variant="outlined"
                color="primary"
                onClick={handleGoMeeting}
              >
                聯絡銷售
              </MyButton>
              <MyButton
                name="form-button"
                variant="contained"
                color="primary"
                type="submit"
                isblock="isblock"
                disabled={isSubmitting}
              >
                立即申請
              </MyButton>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};
export default TrialForm;
