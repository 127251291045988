import _ from "lodash";
import {
  useState,
  useEffect,
  useCallback,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";
import { styled } from "@mui/system";
import { Box, Grid } from "@mui/material";

import BlogCardSection from "./BlogCardSection";
import MyButton from "../tools/MyButton";
import * as BlogModel from "../../models/Blog";

import { BlogContext } from "./BlogContentSection";

const MoreButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: 112,
  paddingBottom: 112,
  [theme.breakpoints.down("sm")]: {
    paddingTop: 56,
    paddingBottom: 44,
  },
}));

const BlogListSection = (
  { per_page = 4, category_slug = undefined, isShowMore = true, isRel = false },
  innerRef
) => {
  const [blogList, setBlogList] = useState([]);
  const [query, setQuery] = useState({});
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  const blog = useContext(BlogContext);

  const requestBlogList = useCallback(
    async (params, reset = false) => {
      setLoading(true);
      const response = await BlogModel.getBlogSearch({
        ...(query || {}),
        ...(params || {}),
        category_slug,
        per_page,
      });
      const { data } = response;
      if (reset === true) {
        setBlogList([...data.data]);
      } else {
        setBlogList([...blogList, ...data.data]);
      }
      setQuery(data.query);
      setTotal(+data.count);
      setLoading(false);
    },
    [per_page, query, blogList, category_slug]
  );

  const resetPage = () => {
    setPage(1);
  };

  const handleNextPage = () => {
    setPage(+page + 1);
    requestBlogList({ ...query, page: +page + 1 });
  };

  const handleQuery = (data) => {
    setQuery(data);
    setBlogList([]);
    requestBlogList({ ...data, page: 1 }, true);
  };

  useImperativeHandle(innerRef, () => ({
    handleQuery,
    handleNextPage,
    requestBlogList,
    resetPage,
  }));

  useEffect(() => {
    if (isRel === true) {
      if (!blog?.id) return;
      const blogId = _.map(blog["jetpack-related-posts"], "id");
      requestBlogList({
        include: blogId.join(","),
      });
    } else {
      requestBlogList({ category_slug });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blog]);

  return (
    <>
      <Grid container spacing={4}>
        {blogList &&
          _.map(blogList, (blog, index) => (
            <Grid item md={6} sm={12} xs={12} key={index}>
              <BlogCardSection type="card" blog={blog} />
            </Grid>
          ))}

        {loading &&
          _.range(per_page).map((index) => (
            <Grid item key={index} md={6} sm={12} xs={12}>
              <BlogCardSection type="card" temp={true} />
            </Grid>
          ))}
      </Grid>
      {isShowMore === true && (
        <MoreButtonWrapper>
          <MyButton
            name="sales-button"
            onClick={handleNextPage}
            sx={{
              display: Math.ceil(+total / +per_page) > +page ? "flex" : "none",
            }}
          >
            {console.log("totalPage:", Math.ceil(+total / +per_page))}
            {console.log("page:", page)}
            查看更多
          </MyButton>
        </MoreButtonWrapper>
      )}
    </>
  );
};

export default forwardRef(BlogListSection);
