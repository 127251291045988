import { useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import InputGroup from "../tools/InputGroup";
import MyButton from "../tools/MyButton";
import BootboxAlertContent from "../tools/BootboxAlertContent";
import { useNavigate } from "react-router-dom";
import useDeal from "../../helpers/useDeal";

const EmailSubscriptionWrapper = styled(Box)(({ theme }) => ({
  ".helper": {
    color: "#2C497F",
    paddingTop: 6,
    lineHeight: 1.6,
  },

  [theme.breakpoints.up("md")]: {
    "& .MuiGrid-root": {
      "&:first-of-type": {
        paddingRight: 36,
      },
    },
  },
}));

const EmailSubscription = ({ bootboxRef, ...props }) => {
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [isSubmitting, setSubmitting] = useState(false);
  const deal = useDeal();
  const handleGoMeeting = (e) => {
    e.preventDefault();
    window.open("https://meetings.hubspot.com/david2085");
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    deal.setDeal("email", value);
  };

  const handleSubmit = async () => {
    const { email } = deal.getDeal();
    if (!email) {
      bootboxRef?.current?.content(
        <BootboxAlertContent
          message="提交錯誤！請重新嘗試"
          bootboxRef={bootboxRef}
        />
      );
      bootboxRef?.current?.show();
      return false;
    } else {
      navigate(`/?email=${email}#TrialFormSection`);
    }
    //  const { email } = ticket.getTicket();
    // setSubmitting(true);
    // if (!email) {
    //   bootboxRef?.current?.content(
    //     <BootboxAlertContent
    //       message="提交錯誤！請重新嘗試"
    //       bootboxRef={bootboxRef}
    //     />
    //   );
    //   bootboxRef?.current?.show();
    //   setSubmitting(false);
    //   return false;
    // }
    // try {
    //   await ticket.submitTicket();
    //   bootboxRef?.current?.content(
    //     <BootboxAlertContent
    //       message="完成提交！感謝您的支持，很快我們會專人處理您的提交。"
    //       bootboxRef={bootboxRef}
    //     />
    //   );
    //   bootboxRef?.current?.show();
    // } catch (error) {
    //   bootboxRef?.current?.content(
    //     <BootboxAlertContent
    //       message="完成提交！感謝您的支持，很快我們會專人處理您的提交。"
    //       bootboxRef={bootboxRef}
    //     />
    //   );
    //   bootboxRef?.current?.show();
    //   console.log("error: ", error);
    // } finally {
    //   setSubmitting(false);
    // }
  };

  return (
    <EmailSubscriptionWrapper className={props?.className}>
      <Grid container>
        <Grid item xs={12} sm={9} md={10}>
          <InputGroup
            placeholder="請輸入您的E-mail"
            value={deal.getDeal()?.email || ""}
            onChange={handleInputChange}
            addOn={
              <MyButton
                onClick={handleSubmit}
                name="email-button"
                disabled={isSubmitting}
              >
                申請限時體驗
              </MyButton>
            }
          />
          <Typography varient="normal" className="helper">
            不容錯過的限時體驗，現在就申請！
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          md={2}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <MyButton onClick={handleGoMeeting} name="sales-button">
            聯絡銷售
          </MyButton>
        </Grid>
      </Grid>
    </EmailSubscriptionWrapper>
  );
};
export default EmailSubscription;
