import { useState, useEffect, useCallback } from "react";
import { Link, Typography, Box, Grid } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import SearchBox from "../tools/SearchBox";

import * as BlogModel from "../../models/Blog";

const Wrapper = styled(Box)(({ theme }) => ({
  paddingTop: 112,
  paddingBottom: 80,
  h1: {
    color: "#58457E",
    paddingBottom: 24,
  },
  "& .tools-container": {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
    },
    "& .button-container": {
      display: "flex",
      gap: 16,
      flexWrap: "wrap",

      "& .MuiLink-root": {
        textDecoration: "none",
        fontWeight: 700,
        cursor: "pointer",
        color: "#58457E",
        border: "2px dashed #58457E",
        borderRadius: 30,
        fontSize: 20,
        padding: "12px 32px",

        "&.selected": {
          background: "#B400AC",
          borderColor: "#B400AC",
          borderStyle: "solid",
          color: "#FFF",
        },
      },
    },
    "& .search-container": {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
}));

const BlogCategorySection = ({ slug = "carebridge", title, listRef }) => {
  const navigate = useNavigate();
  const [catList, setCatList] = useState([]);
  const [value, setValue] = useState(slug);

  const requestCategory = useCallback(async () => {
    const response = await BlogModel.getCategory({
      parent: 191,
    });
    const { data } = response;
    setCatList(data);
  }, []);

  const handleChange = (event) => {
    event.preventDefault();
    const slug = event.target.dataset.slug;
    const id = event.target.dataset.id;
    navigate(`/resources/${slug}`);

    listRef?.current?.handleQuery({ categories: id });
  };

  useEffect(() => {
    requestCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValue(slug);
  }, [slug]);

  return (
    <Wrapper>
      <Typography variant="h1">{title}</Typography>
      <Grid container className="tools-container">
        <Grid item md={7} sm={12} xs={12}>
          <Box className="button-container">
            <Link
              className={value === "carebridge" ? "selected" : ""}
              href="/resources"
              data-slug="carebridge"
              data-id="191"
              onClick={handleChange}
            >
              全部
            </Link>
            {catList.map((row, i) => (
              <Link
                key={i}
                className={row.slug === value ? "selected" : ""}
                href={`/resources/${row.slug}`}
                data-slug={row.slug}
                data-id={row.id}
                onClick={handleChange}
              >
                {row.name}
              </Link>
            ))}
          </Box>
        </Grid>

        <Grid item md={5} sm={12} xs={12} className="search-container">
          <SearchBox listRef={listRef} />
        </Grid>
      </Grid>
    </Wrapper>
  );
};
export default BlogCategorySection;
