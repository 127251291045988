import _ from "lodash";
import { useState, useCallback } from "react";

import * as HubspotModel from "../models/Hubspot";

const useDeal = (formValue = null) => {
  const [dealData, setDealData] = useState(
    formValue || {
      email: undefined,
      firstname: undefined,
      companyName: undefined,
      salesCode: undefined,
      region: undefined,
      subject: "Carebridge 診所系統試用",
      content: undefined,
    }
  );

  const getDeal = () => dealData;

  const setDeal = (f, v = null) => {
    if (_.isPlainObject(f)) {
      setDealData({ ...f });
    } else {
      setDealData({ ...dealData, [`${f}`]: v });
    }
    return dealData;
  };

  const submitDeal = useCallback(
    async (values = null) => {
      let submitValue;
      if (values) {
        setDealData(values);
        submitValue = values;
      } else {
        submitValue = dealData;
      }
      return await HubspotModel.postDeal(submitValue);
    },
    [dealData]
  );

  return {
    getDeal,
    setDeal,
    submitDeal,
  };
};

export default useDeal;
