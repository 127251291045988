import { createTheme } from "@mui/material";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 786,
      md: 1393,
      lg: 1593,
      xl: 2000,
    },
  },
  typography: {
    fontFamily:
      '"Noto Sans TC", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    h1: {
      fontSize: 80,
      fontWeight: 700,
      lineHeight: 1.4,
    },
    h2: {
      fontSize: 60,
      fontWeight: 700,
      lineHeight: 1.4,
    },
    h3: {
      fontSize: 30,
      fontWeight: 700,
      lineHeight: 1.4,
    },
    h4: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: 1.4,
    },
    "large-bold": {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: 1.3,
    },
    "body-intro": {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: "auto",
    },
    "body-main": {
      fontSize: 24,
      fontWeight: 400,
      lineHeight: 1.6,
    },
    "medium-bold": {
      fontSize: 20,
      fontWeight: 700,
      lineHeight: 1.3,
    },
    medium: {
      fontSize: 20,
      fontWeight: 400,
      lineHeight: 1.3,
    },
    caption: {
      fontSize: 18,
      fontWeight: 500,
    },
    "semi-bold": {
      fontSize: 16,
      fontWeight: 600,
    },
    semi: {
      fontSize: 16,
      fontWeight: 400,
    },
    "normal-bold": {
      fontSize: 18,
      fontWeight: 700,
      lineHeight: 1.6,
    },
    normal: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 1.6,
    },
    "small-bold": {
      fontSize: 12,
      fontWeight: 700,
    },
    small: {
      fontSize: 12,
      fontWeight: 400,
    },
    link: {
      color: "#B400AC!important",
      fontWeight: 500,
    },
  },
});

const { breakpoints, typography } = theme;
const themeBreakpoint = {
  ...theme,
  typography: {
    ...typography,
    h1: {
      ...typography.h1,
      [breakpoints.down("sm")]: {
        fontSize: 52,
      },
    },
    h2: {
      ...typography.h2,
      [breakpoints.down("sm")]: {
        fontSize: 34,
      },
    },
    h3: {
      ...typography.h3,
      [breakpoints.down("sm")]: {
        fontSize: 28,
      },
    },
    h4: {
      ...typography.h4,
      [breakpoints.down("sm")]: {
        fontSize: 24,
      },
    },
    "body-intro": {
      ...typography["body-intro"],
      [breakpoints.down("sm")]: {
        fontSize: 20,
      },
    },
    "body-main": {
      ...typography["body-main"],
      [breakpoints.down("sm")]: {
        fontSize: 17,
      },
    },
    medium: {
      ...typography.medium,
      [breakpoints.down("sm")]: {
        fontSize: 17,
      },
    },
    caption: {
      ...typography.caption,
      [breakpoints.down("sm")]: {
        fontSize: 15,
      },
    },
    "small-bold": {
      ...typography["small-bold"],
      [breakpoints.down("sm")]: {
        fontSize: 15,
      },
    },
    small: {
      ...typography.small,
      [breakpoints.down("sm")]: {
        fontSize: 15,
      },
    },
  },
};

export default themeBreakpoint;
