import { useState, useRef, useEffect } from "react";
import { InputBase, Box, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import useBreakpoint from "../../helpers/useBreakpoint";

import { ReactComponent as SearchIcon } from "../../media/svg/icon/search.svg";

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "sx",
})(({ theme, open }) => ({
  display: "flex",
  border: "2px dashed #58457E",
  borderRadius: 30,
  width: 50,
  height: 50,
  "&:hover": {
    ...(!open && {
      borderRadius: 15,
    }),
  },
  "& .MuiIconButton-root": {
    color: "#B400AC",
    width: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  "& .MuiInputBase-root": {
    width: "100%",
    flex: "1 1 auto",
    ...(!open && {
      display: "none",
    }),
  },

  ...(open && {
    width: "100%",
    background: "#B400AC",
    color: "#FFF",
    borderColor: "#B400AC",
    paddingRight: 20,
    [theme.breakpoints.down("md")]: {
      marginBottom: 56,
    },
    [theme.breakpoints.down("sm")]: {
      height: 56,
    },
    "& .MuiIconButton-root path": {
      stroke: "#FFF",
    },
    "& .MuiInputBase-root": {
      width: "100%",
      color: "#FFFFFF",
      paddingLeft: 32,
      paddingTop: 12,
      paddingBottom: 12,
      fontSize: 20,
      "&::placeholder ": {
        color: "#FFFFFF50",
      },
    },
  }),
}));

const SearchBox = ({ listRef }) => {
  const breakpoints = useBreakpoint();
  const ref = useRef();
  const [open, setOpen] = useState(!breakpoints.isMd);
  const [search, setSearch] = useState("");

  const handleButtonClick = () => {
    if (!open) {
      setOpen(true);
      return;
    } else {
      listRef?.current?.handleQuery({ search });
    }
  };

  const handleInputChange = (e) => setSearch(e.target.value);

  useEffect(() => {
    const handleMouseLeave = (event) => {
      if (!breakpoints.isMd) return;
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleMouseLeave);
    return () => document.removeEventListener("mousedown", handleMouseLeave);
  }, [ref, breakpoints.isMd]);

  return (
    <Wrapper open={open} ref={ref}>
      <InputBase
        value={search}
        placeholder="搜索"
        onChange={handleInputChange}
        onKeyUp={(e) =>
          e.key === "Enter" && listRef?.current?.handleQuery({ search })
        }
      />
      <IconButton onClick={handleButtonClick}>
        <SearchIcon />
      </IconButton>
    </Wrapper>
  );
};
export default SearchBox;
