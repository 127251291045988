import { API } from "aws-amplify";

/**
 * @name get Wordpress Post about CareBridge
 * */
export const getBlogSearch = async (data = {}) => {
  return await API.get("carebridgeWebApiProd", "/blog/search", {
    response: true,
    queryStringParameters: data,
  });
};

export const getBlogBySlug = async (slug) => {
  return await getBlogSearch({ slug });
};

export const getCategory = async (data = {}) => {
  return await API.get("carebridgeWebApiProd", "/blog/category_list", {
    response: true,
    queryStringParameters: data,
  });
};
