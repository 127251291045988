import { styled } from "@mui/system";
import { Box, Paper, Typography, alpha } from "@mui/material";

import TrialForm from "../tools/TrialForm";

const TrialFormSectionWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItem: "center",
  padding: "112px 64px",

  [theme.breakpoints.down("sm")]: {
    paddingLeft: 16,
    paddingRight: 16,
  },

  "& .bootbox": {
    boxShadow: "0px 20px 40px rgba(0, 0, 0, 0.15)",
    borderRadius: "0px 30px 30px 30px",
    border: "1px solid rgba(187, 172, 193, 0.5)",
    width: 638,

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      boxShadow: "unset",
    },

    "& .title": {
      height: 100,
      [theme.breakpoints.down("sm")]: {
        height: 48,
      },

      "&::before": {
        content: '""',
        position: "absolute",
        width: 332,
        height: 88,
        background:
          "linear-gradient(180deg, rgba(88, 69, 126, 0.8) 0%, rgba(151, 99, 255, 0.8) 100%)",
        boxShadow: "0px 20px 40px rgba(0, 0, 0, 0.15)",
        borderRadius: "0px 0px 50px 50px",
        [theme.breakpoints.down("sm")]: {
          width: 189,
          height: 40,
        },
      },

      "& .MuiTypography-h3": {
        position: "relative",
        padding: "17px 32px",
        color: "#FFFFFF",
        zIndex: 1,
        [theme.breakpoints.down("sm")]: {
          fontSize: 17,
          padding: "8px 16px",
        },
      },
    },

    "& .TrialForm": {
      padding: "8px 24px",
      borderColor: "rgba(88, 69, 126, 0.3)",
      borderWidth: "1px 0",
      borderStyle: "solid",
    },

    "& .footer": {
      display: "flex",
      justifyContent: "space-between",
      padding: "20px 24px",

      "& .btn-group": {
        display: "flex",
        gap: 16,
      },

      [theme.breakpoints.down("sm")]: {
        "& [name=form-button][variant=outlined][color=secondary]": {
          display: "none",
        },

        "& .MuiButtonBase-root": {
          width: "100%",
        },

        "& .btn-group": {
          width: "100%",
          flexDirection: "column",
        },
      },
    },
  },

  "& .MuiFormControl-root:not(.MuiPhoneNumber)": {
    marginTop: 36,

    "& .MuiInputLabel-root": {
      color: "#2C497F",
      fontSize: 16,

      "&.MuiInputLabel-shrink": {
        transform: "translate(0, -9px) scale(1)",
        "& .MuiFormLabel-asterisk": {
          color: "#E85D75",
        },
      },
    },
    "& .MuiInputBase-root": {
      "& input, textarea": {
        color: "#2C497F",
        border: "1px solid #BBACC1",
        borderRadius: 30,
        "&::placeholder": {
          color: "#2C497F50",
        },
      },
      "& textarea": {
        height: 86,
      },
    },

    "& .MuiPhoneNumber": {
      border: "1px solid #BBACC1",
      borderRadius: 30,
      paddingLeft: 12,
      width: "98%",
      "& .MuiInputBase-root": {
        "&::before": {
          borderBottom: 0,
        },
        "&.Mui-focused": {
          "&::before": {
            borderBottom: 0,
          },
        },
        "& input": {
          border: 0,
          borderLeft: "1px solid #BBACC1",
          padding: "6px 12px",
          background: "#fcfcfb",
          "&:focus": {
            boxShadow: `${alpha(
              theme.palette.primary.main,
              0.25,
            )} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
            borderBottom: 0,
          },
        },
      },
    },
  },
}));

const TrialFormSection = ({ bootboxRef }) => {
  return (
    <TrialFormSectionWrapper id="TrialFormSection">
      <Paper className="bootbox">
        <Box className="title">
          <Typography variant="h3">14天體驗名額登記</Typography>
        </Box>
        <TrialForm bootboxRef={bootboxRef} />
      </Paper>
    </TrialFormSectionWrapper>
  );
};

export default TrialFormSection;
