import { styled } from "@mui/system";
import { Box, Typography, Container } from "@mui/material";

const LogoContainerWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "sx",
})(({ theme, color }) => ({
  paddingTop: 112,
  paddingBottom: 112,
  display: "none",
  [theme.breakpoints.up("md")]: {
    display: "block",
  },

  "& .MuiContainer-root": {
    paddingTop: 80,
    paddingBottom: 80,
    borderRadius: 30,
    ...(color === "primary" && {
      background: "#F0D9EF",
    }),
    ...(color === "secondary" && {
      background: "#E9E5F1",
    }),

    "& h3": {
      textAlign: "center",
      marginBottom: 30,
      color: "#58457E",
    },
    "& .logo-container": {
      display: "flex",
      justifyContent: "center",

      "& img": {
        height: 36,
        "&:not(:last-child)": {
          marginRight: 48,
        },
      },
    },
  },
}));
const LogoContainerSection = ({ color, title, logos = [] }) => {
  return (
    <LogoContainerWrapper color={color}>
      <Container>
        <Typography variant="h3">{title}</Typography>
        <Box className="logo-container">
          {logos.map((logo, i) => (
            <img
              key={i}
              src={logo.logo}
              alt={`Carebridge 診所管理系統 | ${logo.name}`}
            />
          ))}
        </Box>
      </Container>
    </LogoContainerWrapper>
  );
};
export default LogoContainerSection;
