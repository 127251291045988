import Carousel from "react-material-ui-carousel";
import { styled } from "@mui/system";
import { Container, Avatar, Typography, Box } from "@mui/material";

import { ReactComponent as VectorRight } from "../../media/svg/icon/vector-right.svg";
import { ReactComponent as VectorLeft } from "../../media/svg/icon/vector-left.svg";

const CarouselItemWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  paddingLeft: 112,
  paddingRight: 112,
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    paddingLeft: 88,
    paddingRight: 88,
  },
  [theme.breakpoints.down("sm")]: {
    paddingLeft: 52,
    paddingRight: 52,
  },

  "&:hover": {
    background: "unset",
  },

  "& div[draggable=false]": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  "& h2": {
    textAlign: "center",
    fontSize: 60,
    color: "#58457E",

    [theme.breakpoints.down("md")]: {
      fontSize: 34,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },

  "& .doctor": {
    display: "flex",
    flexDirection: "row",

    "& .name": {
      fontSize: 24,
      color: "#2C497F",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingRight: 20,
      [theme.breakpoints.down("md")]: {
        fontSize: 17,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 15,
      },
    },
  },
}));

const ClientProvenSectionWrapper = styled(Box)(({ theme }) => ({
  paddingTop: 112,
  paddingBottom: 112,
  [theme.breakpoints.down("md")]: {
    paddingTop: 64,
    paddingBottom: 64,
  },

  "div:has([aria-label=Previous]), div:has([aria-label=Next])": {
    height: "50%",
  },
  "& [aria-label=Previous], [aria-label=Next]": {
    border: "2px solid #58457E",
    borderRadius: "50%",
    background: "unset",
    width: 88,
    height: 88,
    "&:hover": {
      opacity: "1!important",
      background: "unset",
      border: "2px solid #58457E",
      "& svg": {
        width: 48,
        height: 48,
        path: {
          fill: "#B400AC",
        },
      },
    },

    [theme.breakpoints.down("md")]: {
      width: 44,
      height: 44,
    },

    [theme.breakpoints.down("sm")]: {
      width: 24,
      height: 24,
      padding: 4,
      "& svg": {
        width: 10,
        height: 10,
      },
    },
  },
}));

const CarouselItem = ({ content, name, photo }) => {
  return (
    <CarouselItemWrapper>
      <Typography variant="h2">“{content}”</Typography>
      <Box className="doctor">
        <Box className="name">{name}</Box>
        <Avatar
          alt={`CareBridge 診所管理系統 ｜${name}`}
          src={photo}
          sx={{
            width: 56,
            height: 56,
          }}
        />
      </Box>
    </CarouselItemWrapper>
  );
};

const ClientProvenSection = ({ messages = [] }) => {
  return (
    <Container maxWidth="lg">
      <ClientProvenSectionWrapper>
        <Carousel
          NextIcon={<VectorRight />}
          PrevIcon={<VectorLeft />}
          indicators={false}
          interval={10000}
          navButtonsAlwaysVisible={true}
        >
          {messages.map((msg, i) => (
            <CarouselItem {...msg} key={i} />
          ))}
        </Carousel>
      </ClientProvenSectionWrapper>
    </Container>
  );
};

export default ClientProvenSection;
