import { Box, Container, Typography } from "@mui/material";
import { styled } from "@mui/system";

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "sx",
})(({ theme, align }) => ({
  "& .title": {
    color: "#58457E",
    textAlign: align,
    lineHeight: 1.12,
    margin: 16,

    [theme.breakpoints.down("md")]: {
      fontSize: 34,
      textAlign: "left",
      margin: 0,
      lineHeight: 1.4,
      marginBottom: 8,
    },
  },
  "& .big-description": {
    color: "#2C497F",
    textAlign: align,
    [theme.breakpoints.down("md")]: {
      fontSize: 13,
      textAlign: "left",
      lineHeight: 1.6,
    },
  },
}));
const BigTitle = ({
  title,
  description,
  children,
  align = "center",
  container = true,
}) => {
  return (
    <Wrapper align={align}>
      {container === true ? (
        <Container pl={1} pr={1}>
          <Typography variant="h1" className="title">
            {title}
          </Typography>
          {description && (
            <Typography varient="normal" className="big-description">
              {description}
            </Typography>
          )}
          {children}
        </Container>
      ) : (
        <>
          <Typography variant="h1" className="title">
            {title}
          </Typography>
          {description && (
            <Typography varient="normal" className="big-description">
              {description}
            </Typography>
          )}
          {children}
        </>
      )}
    </Wrapper>
  );
};

export default BigTitle;
