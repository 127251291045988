import { useState, useEffect, useCallback } from "react";
import { Skeleton, Avatar, Box, Typography, Grid, Link } from "@mui/material";
import { styled } from "@mui/system";
import moment from "moment";

import LogoSVG from "../../media/svg/logo/carebridge-logo-s.svg";
import * as BlogModel from "../../models/Blog";

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "sx",
})(({ theme, type }) => ({
  "a.title": {
    textDecoration: "none",
    color: "#2C497F",
  },
  "a.category": {
    color: "#2C497F",
    fontWeight: "bold",
    padding: 4,
  },
  ...(type &&
    type === "banner" && {
      border: "2px solid #58457e",
      borderRadius: 30,
      "& .photo": {
        width: "calc(100% / 12 * 6.5)",
        "& img": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "30px 0 0 30px",
        },
      },

      "& .message-container": {
        padding: theme.spacing(6),
        width: "calc(100% / 12 * 5.5)",
        color: "#2C497F",
        borderLeft: "2px solid #58457E",
      },

      [theme.breakpoints.down("sm")]: {
        border: 0,

        "& .photo": {
          width: "100%",
          border: "2px solid #58457E",
          borderRadius: 30,
          "& img": {
            borderRadius: 30,
          },
        },
        "& .message-container": {
          padding: 0,
          width: "100%",
          borderLeft: 0,
        },
      },
    }),

  ...(type &&
    type === "card" && {
      border: "2px solid #58457e",
      borderRadius: 30,
      "& .photo": {
        width: "100%",
        height: 340,
        borderBottom: "2px solid #58457E",
        borderRadius: "30px 30px 0 0",
        "& img": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "30px 30px 0 0",
        },
      },

      "& .message-container": {
        width: "100%",
        padding: theme.spacing(4),
        color: "#2C497F",
      },

      [theme.breakpoints.down("sm")]: {
        border: 0,

        "& .photo": {
          width: "100%",
          height: 196,
          border: "2px solid #58457E",
          borderRadius: 30,
          "& img": {
            borderRadius: 30,
          },
        },
        "& .message-container": {
          padding: 0,
          width: "100%",
          borderLeft: 0,
        },
      },
    }),
}));

const BlogCardSection = ({ type = "card", blog = {}, temp = false }) => {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(blog);

  const requestBlog = useCallback(async () => {
    if (type === "card" || temp === true) {
      return;
    }
    setLoading(true);
    const response = await BlogModel.getBlogSearch({ per_page: 1 });
    const { data } = response;
    setMessage(data?.data[0] || {});
    setLoading(false);
  }, [type, temp]);

  useEffect(() => {
    if (type === "card") {
      setLoading(false);
      setMessage(blog);
      return;
    }
    requestBlog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestBlog]);

  return (
    <Wrapper type={type}>
      <Grid container>
        <Grid item className="photo">
          {temp || loading ? (
            <Skeleton
              variant="rectangular"
              sx={{
                width: "100%",
                height: "100%",
                minHeight: "196px",
                borderRadius:
                  type === "banner" ? "30px 0 0 30px" : "30px 30px 0 0",
              }}
            />
          ) : (
            <Link
              href={`/articles/${message?.slug}`}
              title={`${message?.title?.rendered} | CareBridge 診所管理系統`}
            >
              <img
                src={message?.jetpack_featured_media_url}
                alt={`${message?.title?.rendered} | CareBridge 診所管理系統`}
              />
            </Link>
          )}
        </Grid>
        <Grid item className="message-container">
          {temp || loading ? (
            <>
              <Skeleton variant="text" animation="wave" sx={{ fontSize: 18 }} />
              <Skeleton variant="text" animation="wave" sx={{ fontSize: 60 }} />
              <Skeleton variant="text" animation="wave" sx={{ fontSize: 60 }} />
              <Skeleton variant="text" animation="wave" sx={{ fontSize: 18 }} />
              <Skeleton variant="text" animation="wave" sx={{ fontSize: 18 }} />
              <Skeleton variant="text" animation="wave" sx={{ fontSize: 18 }} />
              <Grid container>
                <Grid
                  item
                  sx={{
                    width: 52,
                  }}
                >
                  <Skeleton
                    variant="circular"
                    width={42}
                    height={42}
                    sx={{
                      width: 42,
                      height: 42,
                      border: "2px solid #B400AC",
                    }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Skeleton variant="text" sx={{ fontSize: 18 }} />
                  <Skeleton variant="text" sx={{ fontSize: 12 }} />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Typography variant="caption">
                {moment(message?.date).format("dddd, MMMM Do YYYY")}
              </Typography>

              <Link
                className="title"
                href={`/articles/${message?.slug}`}
                title={`${message?.title?.rendered} | CareBridge 診所管理系統`}
              >
                <Typography
                  variant={type === "banner" ? "h2" : "h3"}
                  sx={{
                    textOverflow: "ellipsis",
                    whiteSpace: "pre",
                    overflow: "hidden",
                  }}
                >
                  {message?.title?.rendered}
                </Typography>
              </Link>

              {message?.categories?.map((cats, i) => {
                if (i >= 4) return null;
                return (
                  <Link
                    className="category"
                    title={`${cats.name} | CareBridge 診所管理系統`}
                    href={`/resources/${cats.slug}`}
                    key={cats.id}
                  >
                    <Typography variant="small">#{cats.name} </Typography>
                  </Link>
                );
              })}

              <Typography
                variant="normal"
                dangerouslySetInnerHTML={{ __html: message?.excerpt?.rendered }}
              ></Typography>

              <Grid container sx={{ paddingTop: "24px" }}>
                <Grid
                  item
                  sx={{
                    width: 52,
                  }}
                >
                  <Avatar
                    alt={`CareBridge 診所管理系統`}
                    src={LogoSVG}
                    sx={{
                      width: 42,
                      height: 42,
                      border: "2px solid #B400AC",
                    }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="normal">
                    <strong>CareBridge</strong>
                  </Typography>
                  <br />
                  <Typography variant="small">
                    診所管理系統 carebridge.app
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default BlogCardSection;
