import { Container, Box, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import MyCard from "../tools/MyCard";
// import Marquee from "react-fast-marquee";

const SolutionSectionWrapper = styled(Box)(({ theme }) => ({
  paddingTop: 112,

  [theme.breakpoints.down("md")]: {
    paddingTop: 32,
  },

  "& .title, .subtitle": {
    fontSize: 80,
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 34,
    },
  },
  "& .title": {
    color: "#58457E",
  },
  "& .subtitle": {
    color: "rgba(88, 69, 126, 0.5)",
  },
  "& .card-container-wrapper": {
    position: "relative",
    display: "flex",
    width: "100%",
    // overflowX: "auto",
    overflowY: "hidden",
    paddingTop: 64,
    paddingBottom: 64,
    /* width */
    "&::-webkit-scrollbar": {
      width: 5,
    },
    /* Track */
    "&::-webkit-scrollbar-track": {
      borderRadius: 10,
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 10,
    },

    "& .card-contaienr": {
      display: "flex",
      flex: "1 1 100%",
      gap: 32,

      "&>.MuiBox-root:last-child .img": {
        "&::before": {
          display: "none!important",
        },
      },
    },

    [theme.breakpoints.down("md")]: {
      height: 322,
      paddingTop: 32,
      paddingBottom: 32,
      "& .card-contaienr": {
        position: "absolute",
        gap: 8,
      },
    },
  },
}));

const SolutionSection = ({ title, subtitle, cards = [] }) => {
  const theme = useTheme();
  return (
    <SolutionSectionWrapper id="solution">
      <Container maxWidth="lg">
        <Typography variant="h1" className="title">
          {title}
        </Typography>
        <Typography variant="h1" className="subtitle">
          {subtitle}
        </Typography>
      </Container>
      <Container
        maxWidth="lg"
        sx={{
          [theme.breakpoints.down("md")]: {
            paddingLeft: 0,
            paddingRight: 0,
          },
        }}
      >
        <Box className="card-container-wrapper">
          {/* <Marquee gradient={false} pauseOnHover={true} loop={0}>*/}
          <Box className="card-contaienr">
            {cards.map((card, i) => (
              <MyCard key={i} {...card} />
            ))}
          </Box>
          {/* </Marquee> */}
        </Box>
      </Container>
    </SolutionSectionWrapper>
  );
};
export default SolutionSection;
