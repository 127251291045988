import axios from "axios";

const instance = axios.create({
  baseURL: "https://carebridge.marstree.ltd",
  timeout: 3000000,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

export const submit = async (userinfo) => {
  const response = await instance.post("/admin/login/register", {
    ...userinfo,
  });
  const { data } = response;
  if (data === "FALSE") {
    return false;
  } else {
    return true;
  }
};
