import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import {
  Box,
  Container,
  Grid,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import useDeal from "../../helpers/useDeal";
import InputGroup from "../tools/InputGroup";
import MyButton from "../tools/MyButton";
import Bootbox from "../tools/Bootbox";
// import BootboxAlertContent from "../tools/BootboxAlertContent";

import { ReactComponent as LogoDark } from "../../media/svg/logo/carebridge-logo-dark.svg";

const FooterWrapper = styled(Box)(({ theme }) => ({
  background: "#4D3F6E",
  color: "#FFF",
  paddingTop: 112,
  paddingBottom: 112,
  [theme.breakpoints.down("md")]: {
    paddingTop: 64,
    paddingBottom: 64,
  },
  [theme.breakpoints.down("sm")]: {
    paddingTop: 36,
    paddingBottom: 36,
  },

  "& .logo": {
    height: 64,
    marginBottom: 32,
  },

  "& .menuContainer": {
    display: "flex",
    "& .MuiButtonBase-root": {
      color: "#FFFFFF",
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      marginBottom: 24,

      "& .MuiButtonBase-root": {
        justifyContent: "flex-start",
      },
    },

    "&.other": {
      [theme.breakpoints.down("md")]: {
        flexDirection: "row",
        justifyContent: "center",
      },
      "& .MuiButtonBase-root": {
        fontWeight: 400,
        fontSize: 13,
        textDecoration: "underline",
      },
    },
  },

  "& .InputGroupWrapper": {
    borderColor: "#ffffff",
    marginTop: 16,
    marginBottom: 4,
  },

  "& .MuiDivider-root": {
    height: 24,
    marginBottom: 32,
    borderColor: "#FFFFFF",
  },

  "& .copyRight": {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
}));

const FooterSection = ({ pages = [] }) => {
  const bootboxRef = useRef();
  const navigate = useNavigate();
  const deal = useDeal();
  const [isSubmitting, setSubmitting] = useState(false);

  const [otherPages] = useState([
    { name: "Privacy Policy", url: "/articles/carebridge-privacy" },
    { name: "Terms of Service", url: "/articles/carebridge-terms" },
    { name: "Cookies Settings", url: "/articles/carebridge-privacy" },
  ]);

  const handleSubmit = async () => {
    const { email } = deal.getDeal();

    setSubmitting(true);

    navigate(`/?email=${email}#TrialFormSection`);

    // if (!email) {
    //   bootboxRef?.current?.content(
    //     <BootboxAlertContent
    //       message="提交錯誤！請重新嘗試"
    //       bootboxRef={bootboxRef}
    //     />
    //   );
    //   bootboxRef?.current?.show();
    //   setSubmitting(false);
    //   return false;
    // }
    // try {
    //   await deal.submitTicket();
    //   bootboxRef?.current?.content(
    //     <BootboxAlertContent
    //       message="完成提交！感謝您的支持，很快我們會專人處理您的提交。"
    //       bootboxRef={bootboxRef}
    //     />
    //   );
    //   bootboxRef?.current?.show();
    // } catch (error) {
    //   bootboxRef?.current?.content(
    //     <BootboxAlertContent
    //       message="完成提交！感謝您的支持，很快我們會專人處理您的提交。"
    //       bootboxRef={bootboxRef}
    //     />
    //   );
    //   bootboxRef?.current?.show();
    //   console.log("error: ", error);
    // } finally {
    //   setSubmitting(false);
    // }
  };

  return (
    <>
      <Bootbox ref={bootboxRef} title="提交" />
      <FooterWrapper>
        <Container>
          <Grid container>
            <Grid item sm={12} md={8}>
              <LogoDark className="logo" />
              <Box className="menuContainer">
                {pages.map((page, i) => (
                  <Button
                    key={i}
                    onClick={() => {
                      navigate(page.path);
                    }}
                  >
                    {page.name}
                  </Button>
                ))}
              </Box>
            </Grid>
            <Grid item sm={12} md={4}>
              <Typography variant="semi-bold">訂閱收取最新消息</Typography>
              <InputGroup
                value={deal.getDeal()?.email || ""}
                onChange={(e) => {
                  const { value } = e.target;
                  deal.setDeal("email", value);
                }}
                placeholder="請輸入您的E-mail"
                addOn={
                  <MyButton
                    name="email-button"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    申請限時體驗名額
                  </MyButton>
                }
              />
              <Typography variant="semi">
                訂閱後，您同意我們的隱私政策。
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid container>
            <Grid item sm={12} md={8}>
              <Box className="menuContainer other">
                {otherPages.map((page) => (
                  <Button
                    key={page.name}
                    onClick={() => {
                      navigate(page.url);
                    }}
                  >
                    {page.name}
                  </Button>
                ))}
              </Box>
            </Grid>
            <Grid item sm={12} md={4} className="copyRight">
              <Typography variant="semi">
                © 2023 CareBridge. All rights reserved.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </FooterWrapper>
    </>
  );
};

export default FooterSection;
