import { API } from "aws-amplify";

/**
 * @name get Hubspot contact by email
 * @param String email
 * */
export const getContactByEmail = async ({ email }) => {
  return await API.post("carebridgeWebApiProd", "/hubspot/contact_submit", {
    response: true,
    body: {
      email,
    },
  });
};

export const postTicket = async (ticketData) => {
  return await API.post("carebridgeWebApiProd", "/hubspot/ticket_submit", {
    response: true,
    body: ticketData,
  });
};

export const postDeal = async (dealData) => {
  return await API.post("carebridgeWebApiProd", "/hubspot/deal_submit", {
    response: true,
    body: dealData,
  });
};
