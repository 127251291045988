import { Button, Box } from "@mui/material";
import { styled } from "@mui/system";

import { ReactComponent as ArrowRightIcon } from "../../media/svg/icon/arrow-right.svg";
import { ReactComponent as ArrowRightFilledIcon } from "../../media/svg/icon/arrow-right-filled.svg";

const MyButtonWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "sx",
})(({ theme, name, variant, color, isblock }) => ({
  "& .MuiButton-root.Mui-disabled": {
    opacity: 0.5,
  },
  ...(name === "form-button" && {
    "& .MuiButton-root": {
      borderWidth: 3,
      borderRadius: 30,
      fontSize: 18,

      "&:active,&:focus": {
        borderWidth: 3,
      },

      ...(variant === "contained" &&
        color === "primary" && {
          background: "#B400AC",
          color: "#FFFFFF",
          borderColor: "#BA00AC",
          "&:hover": {
            borderRadius: 15,
            background: "#B400AC",
            borderColor: "#BA00AC",
          },
          ...(isblock === true
            ? {
                padding: "8px 50px",
              }
            : {
                padding: "8px 24px",
              }),
        }),

      ...(variant === "outlined" && {
        color: "#2C497F",
        background: "#FFFFFF",
        ...(isblock === "isblock"
          ? {
              padding: "6px 50px",
            }
          : {
              padding: "6px 24px",
            }),

        ...(color === "primary" && {
          borderColor: "#BA00AC",
          "&:hover": {
            borderColor: "#BA00AC",
            borderWidth: 3,
            borderRadius: 15,
          },
        }),
        ...(color === "secondary" && {
          borderColor: "#BBACC1",
          "&:hover": {
            borderColor: "#BBACC1",
            borderWidth: 3,
            borderRadius: 15,
          },
        }),
      }),
    },
  }),

  ...(name === "problem-button2" && {
    width: "100%",
    "& .MuiButton-root": {
      width: "100%",
      background: "linear-gradient(180deg, #58457E 0%, #9763FF 100%)",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: 30,
      lineHeight: 1.4,
      padding: "12px 12px",
      color: "#FFFFFF",
      borderRadius: 30,
      "& .title": {
        opacity: 0.5,
      },

      "&:hover": {
        borderRadius: 16,
        "& .title": {
          opacity: 1,
        },
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 24,
      },
    },
  }),
  ...(name === "problem-button" && {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "& .MuiButton-root": {
      background: "unset",
      border: "2px solid #fff",
      color: "#ffffff",
      fontSize: 20,
      fontWeight: 700,
      lineHeight: 1.3,
      padding: "8px 24px",
      borderRadius: 30,
      height: 86,
      boxShadow: "0 2px 0 #ffffff",

      "&:hover": {
        borderWidth: "2px 2px 4px 2px",
        boxShadow: "0 4px 0 #FFF",
      },

      [theme.breakpoints.down("md")]: {
        height: 56,
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  }),
  ...(name === "email-button" && {
    "& .MuiButton-root": {
      background: "#b400ac",
      color: "#fff",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: 20,
      lineHeight: 1.3,
      padding: 33,
    },
  }),
  ...(name === "sales-button" && {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "& .MuiButton-root": {
      border: "2px solid #BBACC1",
      boxShadow: "0px 2px 0px #4D3F6E",
      borderRadius: 30,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: 20,
      color: "#2C497F",
      padding: "30px 24px",

      [theme.breakpoints.down("md")]: {
        padding: "11px 32px",
        fontSize: 15,
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      "& .icon": {
        display: "flex",
        padding: "5px 5px 5px 11px",
        "& .hover": {
          display: "none",
        },
      },
      ":hover": {
        padding: "25px 23px",
        [theme.breakpoints.down("md")]: {
          padding: "10px 32px",
        },

        boxShadow: "0px 4px 0px #4D3F6E",
        "& .icon svg:not(.hover)": {
          display: "none",
        },
        "& .hover": {
          padding: "5px 2px",
          display: "flex",
        },
      },
    },
  }),
  ...(name === "new-button" && {
    display: "flex",
    "& .new": {
      background: "#b400ac",
      color: "#fff",
      padding: "2px 8px",
      borderRadius: "30px 0 0 30px",
      fontSize: 16,
      fontWeight: 400,
      display: "flex",
      alignItems: "center",
    },
    "& .MuiButton-root": {
      background: "#ebeffe",
      color: "#2C497F",
      borderRadius: "0 30px 30px 0",
      fontSize: 12,
      fontWeight: 400,
      padding: "6px 8px",
    },
    "&:hover": {
      "& .MuiButton-root": {
        backgrount: "#ccd3f3",
      },
    },
  }),
  ...(name === "red-button" && {
    "& .MuiButton-root": {
      background: "#B400AC",
      color: "#ffffff",
      borderRadius: 30,
      padding: "12px 48px",
      fontSize: 20,
      fontWeight: 700,

      "&:hover": {
        background: "#B400AC",
        color: "#ffffff",
        borderRadius: 16,
      },
    },
  }),
  ...(name === "red-thinText-button" && {
    // width: "100%",
    display: "flex",
    "& .MuiButton-root": {
      width: "100%",
      margin: "0 28px",
      // marginLeft: 28,
      // marginRight: 28,
      background: "#B400AC",
      color: "#ffffff",
      borderRadius: 30,
      padding: "12px 48px",
      fontSize: 20,
      fontWeight: 400,

      "&:hover": {
        background: "#B400AC",
        color: "#ffffff",
        borderRadius: 16,
      },
    },
  }),
}));

const MyButton = ({ name, children, isblock, ...rest }) => {
  return (
    <MyButtonWrapper
      name={name}
      isblock={isblock}
      {...{
        variant: rest.variant,
        color: rest.color,
      }}
    >
      {name === "new-button" && <Box className="new">New</Box>}
      <Button {...rest}>
        {name === "problem-button2" ? (
          <span className="title">{children}</span>
        ) : (
          children
        )}
        <Box className="icon">
          {name === "sales-button" && <ArrowRightIcon />}
          {name === "sales-button" && (
            <ArrowRightFilledIcon className="hover" />
          )}
        </Box>
      </Button>
    </MyButtonWrapper>
  );
};

export default MyButton;
