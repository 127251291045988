import { styled } from "@mui/system";
import { Container, Box, useTheme } from "@mui/material";

import Marquee from "react-fast-marquee";
import { ReactComponent as CircleIcon } from "../../media/svg/icon/circle.svg";

const MarqueeWrapper = styled(Box)(({ theme }) => ({
  background: "#58457E",
  color: "#fff",
  fontSize: 24,
  paddingTop: 16,
  paddingBottom: 16,

  "& .dot": {
    padding: "3px 12px",
  },

  "&.upper": {
    borderRadius: "30px 30px 0 0 ",
    borderBottom: "2px dashed #FFFFFF",
  },

  "&.lower": {
    borderRadius: "0 0 30px 30px",
  },
}));

const MyMarqueeSection = ({ content }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        paddingBottom: 8,
        [theme.breakpoints.down("md")]: {
          paddingBottom: 3,
        },
      }}
    >
      <Container maxWidth="lg">
        <MarqueeWrapper className="upper">
          <Marquee gradient={false} loop={0}>
            {content.map((c, i) => (
              <span key={i}>
                {c}
                <CircleIcon className="dot" />
              </span>
            ))}
          </Marquee>
        </MarqueeWrapper>
        <MarqueeWrapper className="lower">
          <Marquee gradient={false} loop={0} direction="right">
            {content.map((c, i) => (
              <span key={i}>
                {c}
                <CircleIcon className="dot" />
              </span>
            ))}
          </Marquee>
        </MarqueeWrapper>
      </Container>
    </Box>
  );
};

export default MyMarqueeSection;
