import _ from "lodash";
import { useState, useCallback } from "react";

import * as HubspotModel from "../models/Hubspot";

const useTicket = (formValue = null) => {
  const [ticketData, setTicketData] = useState(
    formValue || {
      email: undefined,
      firstname: undefined,
      phone: undefined,
      companyName: undefined,
      region: undefined,
      subject: "Carebridge 診所系統 合作夥伴申請",
      content: undefined,
      offers: undefined,
      candidates: undefined,
    }
  );

  const getTicket = () => ticketData;

  const setTicket = (f, v = null) => {
    if (_.isPlainObject(f)) {
      setTicketData({ ...f });
    } else {
      setTicketData({ ...ticketData, [`${f}`]: v });
    }
    return ticketData;
  };

  const submitTicket = useCallback(
    async (values = null) => {
      let submitValue;
      if (values) {
        setTicketData(values);
        submitValue = values;
      } else {
        submitValue = ticketData;
      }
      return await HubspotModel.postTicket(submitValue);
    },
    [ticketData]
  );

  return {
    getTicket,
    setTicket,
    submitTicket,
  };
};

export default useTicket;
