import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container, Box, Typography } from "@mui/material";

import HeroSection from "../components/sections/HeroSection";
import EmailSubscription from "../components/sections/EmailSubscription";
import ProblemSection from "../components/sections/ProblemSection";
import SolutionSection from "../components/sections/SolutionSection";
import MyMarqueeSection from "../components/sections/MyMarqueeSection";
import TrialFormSection from "../components/sections/TrialFormSection";
import MySlideSection from "../components/sections/MySliderSection";
import ClientProvenSection from "../components/sections/ClientProvenSection";
import LogoContainerSection from "../components/sections/LogoContainerSection";
import CTASection from "../components/sections/CTASection";
import WhySection from "../components/sections/WhySection";
import Bootbox from "../components/tools/Bootbox";

// import photo
import SystemPhoto from "../media/img/system-photo1.png";
import ProblemPhoto from "../media/img/cover-problem.png";
import SystemScreenshot1Photo from "../media/img/screenshots/appzhengli.png";
import SystemScreenshot2Photo from "../media/img/screenshots/appzhiyao.png";
import SystemScreenshot3Photo from "../media/img/screenshots/appyuyue.png";
import SystemScreenshot4Photo from "../media/img/screenshots/appwangzhan.png";
import WhyPhoto from "../media/img/cover-why.png";
import FAQSection from "../components/sections/FAQSection";
// import doctor avatar
import AvatorPhoto from "../media/img/doctors/Avatar.png";
import AvatorPhoto2 from "../media/img/doctors/Avatar2.png";
import AvatorPhoto3 from "../media/img/doctors/Avatar3.png";
import AvatorPhoto4 from "../media/img/doctors/Avatar4.png";
// import client logo
import ClientLogo1 from "../media/img/client_logo/image1.svg";
import ClientLogo2 from "../media/img/client_logo/image2.svg";
import ClientLogo3 from "../media/img/client_logo/image3.svg";
import ClientLogo4 from "../media/img/client_logo/image4.svg";
import ClientLogo5 from "../media/img/client_logo/image5.svg";
// import partner logo
import AdobeIcon from "../media/img/partners/adobe.svg";
import AWSIcon from "../media/img/partners/aws.svg";
import BitrixIcon from "../media/img/partners/bitrix.svg";
import BocmacauIcon from "../media/img/partners/bocmacau.svg";
import IngramIcon from "../media/img/partners/ingram-micro.svg";

const Homepage = () => {
  const bootboxRef = useRef();
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash.replace("#", "");

    const element = document.getElementById(hash);
    if (element?.offsetTop) {
      window.scrollTo({ top: element.offsetTop, left: 0, behavior: "smooth" });
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <>
      <Bootbox title="提交" ref={bootboxRef}></Bootbox>
      <HeroSection
        title={"「橋梁，連接你我，傳承健康。」"}
        content={
          "這是我們的使命，我們相信一個好的診所管理系統可以成為中醫西醫等等的診所醫療團隊和患者之間的橋梁，連接你我，傳承健康。我們的系統可以幫助您更好地管理診所，提高運營效率和患者體驗，同時保護患者的隱私和資訊安全。"
        }
        photo={SystemPhoto}
        bootboxRef={bootboxRef}
      />
      <Container maxWidth="lg" className="visible-md">
        <EmailSubscription className="visible-md" bootboxRef={bootboxRef} />
      </Container>
      <ProblemSection
        title="中醫或西醫診所缺乏有效的管理系統時會面臨什麼問題？"
        questions={[
          {
            question: "繁瑣的手動操作",
            answer:
              "缺乏系統意味著診所工作人員需要進行繁瑣的手動操作，例如手動記錄病歷、手動開立處方等。這種方式可能會導致出現錯誤或延長等待時間，影響診所的效率和準確性。",
          },
          {
            question: "資訊不對稱",
            answer:
              "沒有完整的病歷、處方和報告紀錄可能會導致資訊不對稱的問題，這可能會影響醫療團隊之間的溝通和患者的困惑。例如，當某位病人轉診到另一家診所時，如果病歷和處方沒有完整的紀錄，新診所的醫生可能需要重新診斷，這可能會導致時間和金錢的浪費。",
          },
          {
            question: "紙質檔案管理問題",
            answer:
              "當診所使用紙質檔案時，這些檔案可能會遭受損失或受損。例如，檔案可能會被燒毀、水淹或被偷竊。這可能會導致對患者和診所造成損失和法律風險。",
          },
          {
            question: "資訊保密問題",
            answer:
              "缺乏系統意味著診所工作人員需要進行繁瑣的手動操作，例如手動記錄病歷、手動開立處方等。這種方式可能會導致出現錯誤或延長等待時間，影響診所的效率和準確性。",
          },
          {
            question: "醫生和護士之間的協調問題",
            answer:
              "沒有一個系統來協調醫生和護士之間的工作流程，可能會導致混亂和錯誤。例如，當護士錯誤地標記了患者的病歷時，醫生可能會根據錯誤的資訊來進行診斷和治療，這可能會對患者的健康造成嚴重影響。",
          },
          {
            question: "無法追蹤和分析數據",
            answer:
              "沒有系統意味著診所無法追蹤和分析重要的數據，例如患者就診次數、診斷結果和藥物使用情況等。這些數據對於制定治療計劃、評估治療效果和改進醫療流程至關重要。",
          },
          {
            question: "缺乏與患者互動的渠道",
            answer:
              "沒有系統意味著診所可能沒有與患者進行互動的渠道。例如，當患者需要查詢診所的服務和政策時，他們可能無法找到一個方便的渠道來進行查詢。這可能會導致患者的不滿和不信任，進而影響診所的聲譽和業務。",
          },
          {
            question: "錯誤的藥物管理",
            answer:
              "沒有系統可能導致藥物錯誤，例如錯誤的處方或藥物交互作用。這可能會對患者的健康造成嚴重威脅，進而對診所造成法律風險。",
          },
        ]}
        photo={ProblemPhoto}
      />
      <SolutionSection
        title="你所需要的一切都在"
        subtitle="CareBridge"
        cards={[
          {
            image: "hand",
            title: "減少手動操作",
            description:
              "使用系統可以減少手動操作，例如手動記錄病歷和開立處方，從而提高效率和準確性。",
          },
          {
            image: "together",
            title: "統一資訊",
            description:
              "系統可以提供完整的病歷、處方和報告紀錄，從而解決資訊不對稱的問題，並且可以提高醫療團隊之間的溝通和患者的困惑。",
          },
          {
            image: "ui-design",
            title: "數碼化檔案管理",
            description:
              "使用系統可以避免紙質檔案管理問題，從而保護患者和診所免受損失和法律風險。",
          },
          {
            image: "locked",
            title: "資訊保密",
            description:
              "系統可以提供授權存取病歷和處方等，從而防止未經授權的人員存取資訊，保護患者和診所的隱私。",
          },
          {
            image: "feedback",
            title: "醫生和護士之間的協調",
            description:
              "系統可以提供協調醫生和護士之間的工作流程，從而減少混亂和錯誤。",
          },
          {
            image: "phone",
            title: "追蹤和分析數據",
            description:
              "系統可以提供追蹤和分析數據的能力，從而提高治療計劃的準確性和評估治療效果的能力。",
          },
          {
            image: "website-design",
            title: "診所網站功能",
            description:
              "系統可以提供與患者互動的渠道，從而提高患者滿意度和診所的聲譽。",
          },
          {
            image: "ui-design2",
            title: "藥物管理",
            description:
              "使用系統可以避免藥物錯誤，例如錯誤的處方或藥物交互作用，從而保護患者的健康和診所的法律風險。",
          },
        ]}
      />
      <MyMarqueeSection
        content={[
          "診症管理",
          "診症單系統",
          "經驗藥方",
          "處理方法",
          "藥物用法",
          "藥物管理",
          "客戶管理",
          "客戶等級管理",
          "貨品類型管理",
          "貨品單位管理",
          "庫存貨品明細",
          "採購管理",
          "採購單管理",
          "倉庫管理",
          "倉存情況管理",
          "調貨管理",
          "盤點貨品功能",
          "貨品分類功能",
          "療程管理",
          "銷售單",
        ]}
      />
      <TrialFormSection bootboxRef={bootboxRef} />
      <MySlideSection
        title={
          <Box>
            讓您的中醫西醫診所
            <br className="visible-sm" />
            智能化升級
            <br />
            CareBridge
            <br className="hidden-md" />
            四大功能可幫到您
          </Box>
        }
        description="診歷系統、配藥系統、預約系統和診所網頁製作系統，能夠幫助您提升工作效率、方便患者預約、精準管理配藥和打造優質的診所網站，讓您更好地了解業務運營情況和發展趨勢，提高服務質量和客戶滿意度，助力診所快速發展。"
        list={[
          {
            title: "診歷系統",
            photo: SystemScreenshot1Photo,
            points: [
              {
                caption: "方便記錄與管理",
                description:
                  "自動整理病歷，方便醫生追蹤治療進度、歷史用藥紀錄及患者狀況，減少繁複的人工工作。",
              },
              {
                caption: "提高工作效率",
                description:
                  "診歷系統可以減少醫生手動處理病歷的時間，從而提高工作效率，使醫生有更多時間關注患者的診療。",
              },
              {
                caption: "規範化管理",
                description:
                  "診歷系統可以更好地管理患者信息，例如通過病歷系統自動發送通知和提醒，提高患者的就診率和忠誠度。",
              },
              {
                caption: "數據分析",
                description:
                  "數據分析提供了全面的統計信息，醫生可以從數據中看出病患的疾病狀況，進而優化治療方案。",
              },
            ],
          },
          {
            title: "配藥系統",
            photo: SystemScreenshot2Photo,
            points: [
              {
                caption: "自動識別處方",
                description:
                  "配藥系統可以自動識別處方，減少手工操作和錯誤率，提高工作效率和精準度。",
              },
              {
                caption: "智能配藥",
                description:
                  "配藥系統可以根據處方智能配藥，提高配藥效率和準確性，確保病人用藥安全。",
              },
              {
                caption: "自動核對藥品信息",
                description:
                  "配藥系統可以自動核對藥品信息，避免藥品混淆或重複開藥，減少人為失誤和病人的風險。",
              },
              {
                caption: "方便快捷的藥房管理",
                description:
                  "配藥系统可以方便快捷地管理药房，包括药品库存、进货、出库等，提高工作效率和管理水平。",
              },
            ],
          },
          {
            title: "預約系統",
            photo: SystemScreenshot3Photo,
            points: [
              {
                caption: "提高工作效率",
                description:
                  "預約系統可以大大減少人工管理預約的工作量，使醫生和員工可以更專注於患者的護理和診療工作，從而提高工作效率和生產力。",
              },
              {
                caption: "提供更好的服務",
                description:
                  "預約系統可以使患者方便地在任何時間預約診療，不必再等待電話或親自前往診所預約。這也可以降低患者因預約問題而感到不滿的可能性，提供更好的服務體驗。",
              },
              {
                caption: "增加診所收入",
                description:
                  "預約系統可以確保診所的預約時間得到最大化利用，同時減少患者“爽約”的情況，從而增加診所的收入。",
              },
              {
                caption: "方便管理和統計",
                description:
                  "預約系統可以方便地管理患者信息、預約記錄和診所日程安排，並提供實時數據分析，幫助診所管理者更好地了解診所的運營情況，為未來的決策提供更好的依據。",
              },
            ],
          },
          {
            title: "網頁系統",
            photo: SystemScreenshot4Photo,
            points: [
              {
                caption: "建立專屬診所網頁",
                description:
                  "透過網頁製作系統，診所能夠建立專屬的診所網頁，展示診所的專業形象及服務項目，吸引更多潛在病患。",
              },
              {
                caption: "提高診所形象及網上曝光度",
                description:
                  "診所的網頁可提高其在網絡上的曝光度，進一步提升其形象，讓更多人知曉該診所的存在。",
              },
              {
                caption: "提供豐富診療資訊及常見病例解答",
                description:
                  "診所網頁可提供豐富的診療資訊及常見病例解答，增強病人對診所的信心，吸引更多病患到訪。",
              },
              {
                caption: "提供在線預約功能及方便病人了解診所詳情",
                description:
                  "診所網頁製作系統還能提供在線預約功能，方便病人預約就診，同時也能讓病人更方便地了解診所的詳情，如醫生資訊、診療服務、地理位置等。",
              },
            ],
          },
        ]}
      />
      <ClientProvenSection
        messages={[
          {
            content:
              "使用CareBridge的診所管理系統，我們的預約量增加了30％，讓我們更好地服務我們的患者。",
            name: "張醫生",
            photo: AvatorPhoto,
          },
          {
            content:
              "我們的診所從未如此高效。CareBridge的配藥系統讓我們節省了大量時間，讓我們有更多時間專注於病人護理。",
            name: "王醫生",
            photo: AvatorPhoto2,
          },
          {
            content:
              "在我們的診所網站上使用CareBridge的網頁製作系統，為我們帶來了更多的線上預約，並提高了網站的使用率。",
            name: "李醫生",
            photo: AvatorPhoto3,
          },
          {
            content:
              "診所管理系統幫助我們實時了解我們的業務運營情況，以便更好地管理我們的診所。",
            name: "林醫生",
            photo: AvatorPhoto4,
          },
        ]}
      />
      <LogoContainerSection
        color="primary"
        title="人氣品牌一致推薦使用"
        logos={[
          {
            name: "快樂港仁",
            logo: ClientLogo1,
          },
          {
            name: "健絡通藥業有限公司",
            logo: ClientLogo2,
          },
          {
            name: "傳正堂",
            logo: ClientLogo3,
          },
          {
            name: "御品藥房",
            logo: ClientLogo4,
          },
          {
            name: "醫道惠民醫館",
            logo: ClientLogo5,
          },
        ]}
      />
      <LogoContainerSection
        color="secondary"
        title="強大合作伙伴"
        logos={[
          {
            name: "中國銀行澳門",
            logo: BocmacauIcon,
          },
          {
            name: "Adobe",
            logo: AdobeIcon,
          },
          {
            name: "Amazon Web Services",
            logo: AWSIcon,
          },
          {
            name: "Ingram micro",
            logo: IngramIcon,
          },
          {
            name: "Bitrix",
            logo: BitrixIcon,
          },
        ]}
      />
      <CTASection
        title={
          <>
            馬上行動!
            <br className="visible-sm" /> 提升您診所的效率
          </>
        }
        subtitle={
          <>
            立即採用
            <br className="visible-sm" /> CareBridge！
          </>
        }
        description="立即預約 CareBridge 線上演示，了解如何透過智能化的診所管理系統提高工作效率，提供更好的服務。限時優惠，機會難得！"
        bootboxRef={bootboxRef}
      />
      <WhySection
        photo={WhyPhoto}
        title={
          <>
            選擇最適合您的診所管理系統 <br className="hidden-md" />
            CareBridge 具有三大優勢：
          </>
        }
        list={[
          {
            caption: "全面解決方案",
            description:
              "CareBridge提供完整的工具套件，以管理您的診所運營。從電子病歷記錄到預約排程和網站建設，CareBridge擁有您需要的一切，可使您簡化工作流程並提高病患護理水平。",
          },
          {
            caption: "使用友好的界面",
            description:
              "CareBridge平台是根據使用者需求而設計的。直觀的界面使您的員工能輕鬆學習和使用系統，減少繁瑣的培訓需求及錯誤的可能性。",
          },
          {
            caption: "客戶支持",
            description:
              "CareBridge提供卓越的客戶支持，以確保您充分利用平台。我們的專家團隊隨時可用，以回答您的問題並提供有效使用系統的指導。",
          },
        ]}
      />
      <FAQSection
        title="FAQs"
        description={
          <>
            <Typography variant="h3">
              以下是一些常見的
              <br className="visible-md" />
              CareBridge <br className="hidden-md" /> 診所管理系統
              <br className="visible-md" />
              常見問題集：
            </Typography>
            <Typography variant="normal">
              如果你有一個問題沒有在我們的常見問題解答中得到回答，那麼請聯繫我們並讓我們知道。
              <br />
              <br />
            </Typography>
          </>
        }
        questions={[
          {
            question: "CareBridge是什麼？",
            answer:
              "CareBridge是一個全面的診所管理系統，包括電子病歷、預約排班、藥物配藥和網站建設工具。它旨在幫助診所改善工作流程、病患護理和業務運營。",
          },
          {
            question: "使用CareBridge的好處是什麼？",
            answer:
              "使用CareBridge的好處包括改善病患護理、簡化診所運營和提高效率。憑藉電子病歷、藥物配藥和預約排班工具，診所可以更好地管理病患護理，確保其運營順利。",
          },
          {
            question: "CareBridge是否易於使用？",
            answer:
              "是的，CareBridge設計考慮到用戶。平台具有直觀的界面，使您的工作人員易於學習和使用系統，減少了廣泛的培訓需求，並減少了錯誤。",
          },
          {
            question: "CareBridge是否提供客戶支持？",
            answer:
              "是的，CareBridge提供優質的客戶支持，確保您充分利用平台。我們的專家團隊隨時為您解答問題，並提供使用系統的指導。",
          },
          {
            question: "如何開始使用CareBridge？",
            answer:
              "您可以通過聯繫我們的銷售團隊或在我們的網站上請求演示開始使用CareBridge。我們的團隊將與您合作，了解您的需求，為您的診所提供定制的解決方案。",
          },
        ]}
      />
    </>
  );
};

export default Homepage;
