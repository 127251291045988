import { DialogContent, Typography, DialogActions } from "@mui/material";
import MyButton from "./MyButton";

const BootboxAlertContent = ({ message, bootboxRef }) => {
  return (
    <>
      <DialogContent dividers>
        <Typography variant="normal">{message}</Typography>
      </DialogContent>
      <DialogActions>
        <MyButton name="red-button" onClick={() => bootboxRef?.current?.hide()}>
          確定
        </MyButton>
      </DialogActions>
    </>
  );
};

export default BootboxAlertContent;
