import { styled } from "@mui/system";
import { Container, Box, Typography } from "@mui/material";
import EmailSubscription from "./EmailSubscription";

const Wrapper = styled(Box)(({ theme }) => ({
  paddingTop: 112,
  paddingBottom: 112,

  h2: {
    paddingBottom: 24,
    color: "#58457E",
    textAlign: "center",
  },

  "& .description": {
    color: "#2c497f",
  },
  [theme.breakpoints.down("md")]: {
    paddingTop: 64,
    paddingBottom: 64,
    h2: {
      paddingBottom: 16,
      textAlign: "left",
    },
  },
}));
const CTASection = ({ title, subtitle, description, bootboxRef }) => {
  return (
    <Wrapper>
      <Container maxWidth="lg">
        <Typography variant="h2">{title}</Typography>
        <Typography variant="h2">{subtitle}</Typography>
        <Typography variant="small" className="description">
          {description}
        </Typography>
        <Box
          sx={{
            paddingTop: 3,
          }}
        >
          <EmailSubscription bootboxRef={bootboxRef} />
        </Box>
      </Container>
    </Wrapper>
  );
};
export default CTASection;
