import moment from "moment";
import { useState, useEffect, useCallback, createContext } from "react";
import { styled } from "@mui/system";
import {
  Skeleton,
  Grid,
  Avatar,
  Box,
  Container,
  Typography,
  Link,
} from "@mui/material";
import SEOTag from "../tools/SEOTag";
import ShareButtons from "../tools/ShareButtons";
import useBreakpoint from "../../helpers/useBreakpoint";
import * as BlogModel from "../../models/Blog";

import LogoSVG from "../../media/svg/logo/carebridge-logo-s.svg";

export const BlogContext = createContext();

const Wrapper = styled(Box)(({ theme }) => ({
  "& .banner-container": {
    color: "#2C497F",
    [theme.breakpoints.up("sm")]: {
      background:
        "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(77, 63, 110, 0.2) 100%), #F8F9FA",
      borderBottom: "4px solid #58457E",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 24,
    },
    [theme.breakpoints.between("sm", "md")]: {
      paddingTop: 56,
      marginBottom: 48,
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: 120,
      marginBottom: 48,
    },
    "& .main": {
      [theme.breakpoints.up("md")]: {
        paddingLeft: 300,
        paddingRight: 300,
      },
      "& a.category": {
        ...theme.typography["normal"],
      },
      "& .image-container": {
        position: "relative",
        display: "flex",
        marginTop: 24,
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },

        "& img.thumbnail": {
          position: "absolute",
          border: "2px solid #58457E",
          borderRadius: 30,
        },
      },
      "& .thumbnail-xs": {
        border: "2px solid #58457E",
        width: "100%",
        borderRadius: 30,
        marginTop: 32,
        marginBottom: 32,
        [theme.breakpoints.down("sm")]: {
          display: "block",
        },
        [theme.breakpoints.up("sm")]: {
          display: "none",
        },
      },
      "& .shareButtons": {
        paddingTop: 14,
        display: "flex",
        justifyContent: "flex-end",
      },
    },
  },
  "#blog-content": {
    color: "#2C497F",
    ...theme.typography["body-main"],
    [theme.breakpoints.up("md")]: {
      paddingLeft: 260 + 64,
      paddingRight: 260 + 64,
    },
    h1: {
      ...theme.typography.h1,
    },
    h2: {
      ...theme.typography.h2,
    },
    h3: {
      ...theme.typography.h3,
    },
    h4: {
      ...theme.typography.h4,
    },
    h5: {
      ...theme.typography["body-intro"],
    },
    h6: {
      ...theme.typography["caption"],
    },
    img: {
      width: "100%",
      border: "2px solid #58457E",
      borderRadius: 30,
    },
    blockquote: {
      borderLeft: "5px solid #B400AC",
      display: "inline-flex",
      width: "100%",
      paddingLeft: 8,
      background: "#fff",
      borderRadius: 5,
      margin: 0,
    },
  },
}));

const BlogContentSection = ({ slug, children }) => {
  const breakpoints = useBreakpoint();
  const [loading, setLoading] = useState(true);
  const [blog, setBlog] = useState({});

  const lazyLoad = () => {
    // handle lazyload image
    const images = document.querySelectorAll("img.lazyload");
    if (images.length > 0) {
      images.forEach((image) => {
        image.src = image.dataset.origSrc;
      });
    }
    // handle toc anchors
    const tocAnchors = document.querySelectorAll("#ez-toc-container a");
    if (tocAnchors.length > 0) {
      tocAnchors.forEach((tocAnchor) => {
        tocAnchor.href = tocAnchor.href.replace(/^(.+)#/i, "#");
      });
    }
    // handle toc headings and disable it
    const tocHeadings = document.querySelector(
      "#ez-toc-container label[aria-label='內容目錄']"
    );
    if (tocHeadings) {
      tocHeadings.style.display = "none";
    }
    // handle toc ez-toc-title-toggle and disable it
    const tocTitleToggle = document.querySelector(".ez-toc-title-toggle");
    if (tocTitleToggle) {
      tocTitleToggle.style.display = "none";
    }

    const imageContainer = document.querySelector(".image-container");
    const thumbnail = document.querySelector(".thumbnail");
    const bannerContainer = document.querySelector(".banner-container");
    if (imageContainer && thumbnail && bannerContainer) {
      //handle .image-contaienr height equal to img.thumbnail height /2 ,
      imageContainer.style.height = thumbnail.clientHeight / 2 + "px";
      thumbnail.style.bottom = -thumbnail.clientHeight / 2 + "px";
      if (breakpoints.isMd) {
        // handle the width of img.thumbnail is equal to .image-container's width plus 70
        thumbnail.style.width = imageContainer.clientWidth + 70 + "px";
        //handle img.thumbnail left is equal to .image-container's left - 35px
        thumbnail.style.left = imageContainer.clientLeft - 35 + "px";
        bannerContainer.style.marginBottom =
          thumbnail.clientHeight / 2 + 48 + "px";
      } else if (breakpoints.isSm || breakpoints.isXs) {
        // handle the width of img.thumbnail is equal to .image-container's width plus 70
        thumbnail.style.width = imageContainer.clientWidth + "px";
        //handle img.thumbnail left is equal to .image-container's left - 35px
        thumbnail.style.left = imageContainer.clientLeft + "px";
        bannerContainer.style.marginBottom =
          thumbnail.clientHeight / 2 + 48 + "px";
      }
    }
  };

  const requestBlog = useCallback(async () => {
    setLoading(true);
    const response = await BlogModel.getBlogBySlug(slug);
    const { data } = response;
    setBlog(data?.data[0] || {});
    setLoading(false);
    setTimeout(lazyLoad, 1000);
    lazyLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  useEffect(() => {
    requestBlog(slug);
  }, [slug, requestBlog]);

  useEffect(() => {
    lazyLoad();

    window.addEventListener("resize", lazyLoad);
    return () => window.removeEventListener("resize", lazyLoad);
  });

  return (
    <>
      <BlogContext.Provider value={blog}>
        <SEOTag
          title={`${blog?.title?.rendered} | Carebridge`}
          description={blog?.excerpt?.rendered}
          name="Carebridge| 凱爾診所管理系統"
          type="article"
          image={blog?.jetpack_featured_media_url}
        />
        <Wrapper>
          <Box className="banner-container">
            <Container maxWidth="lg" className="main">
              <Box>
                {loading ? (
                  <></>
                ) : blog?.jetpack_featured_media_url !== "" ? (
                  <img
                    className="thumbnail-xs"
                    src={blog?.jetpack_featured_media_url}
                    alt={`${blog?.title?.rendered} | CareBridge 診所管理系統`}
                  />
                ) : null}
              </Box>
              <Typography variant="caption">
                {loading ? (
                  <Skeleton variant="text" />
                ) : (
                  moment(blog?.date).format("dddd, MMMM Do YYYY")
                )}
              </Typography>

              <Typography variant="h1">
                {loading ? <Skeleton variant="text" /> : blog?.title?.rendered}
              </Typography>

              {!loading &&
                blog?.categories?.map((cats, i) => {
                  if (i >= 4) return null;
                  return (
                    <Link
                      className="category"
                      title={`${cats.name} | CareBridge 診所管理系統`}
                      href={`/resources/${cats.slug}`}
                      key={cats.id}
                    >
                      <Typography variant="normal">#{cats.name} </Typography>
                    </Link>
                  );
                })}

              <Grid container sx={{ paddingTop: "24px" }}>
                {loading ? (
                  <Grid container>
                    <Grid
                      item
                      sx={{
                        width: 52,
                      }}
                    >
                      <Skeleton
                        variant="circular"
                        width={42}
                        height={42}
                        sx={{
                          width: 42,
                          height: 42,
                          border: "2px solid #B400AC",
                        }}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Skeleton variant="text" sx={{ fontSize: 18 }} />
                      <Skeleton variant="text" sx={{ fontSize: 12 }} />
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Grid
                      item
                      sx={{
                        width: 52,
                      }}
                    >
                      <Avatar
                        alt={`CareBridge 診所管理系統`}
                        src={LogoSVG}
                        sx={{
                          width: 42,
                          height: 42,
                          border: "2px solid #B400AC",
                        }}
                      />
                    </Grid>
                    <Grid item xs={10} sm={8}>
                      <Typography variant="normal">
                        <strong>CareBridge</strong>
                      </Typography>
                      <br />
                      <Typography variant="small">
                        診所管理系統 carebridge.app
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3} className="shareButtons">
                      <ShareButtons
                        url={`https://carebridge.app/articles/${blog?.slug}`}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <Box className="image-container">
                {loading ? (
                  <></>
                ) : blog?.jetpack_featured_media_url !== "" ? (
                  <img
                    className="thumbnail"
                    src={blog?.jetpack_featured_media_url}
                    alt={`${blog?.title?.rendered} | CareBridge 診所管理系統`}
                  />
                ) : (
                  <></>
                )}
              </Box>
            </Container>
          </Box>
          <Box className="content-container">
            <Container maxWidth="lg">
              <Box id="blog-content">
                {loading ? (
                  <>
                    <p>
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                    </p>
                    <p>
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                    </p>
                    <p>
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                    </p>
                  </>
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: blog?.content?.rendered,
                    }}
                  />
                )}
              </Box>
            </Container>
          </Box>
        </Wrapper>
        {children}
      </BlogContext.Provider>
    </>
  );
};

export default BlogContentSection;
