import { useMediaQuery, useTheme } from "@mui/material";

const useBreakpoint = () => {
  const theme = useTheme();

  const breakpoints = {
    isXs: useMediaQuery(theme.breakpoints.up("xs")),
    isSm: useMediaQuery(theme.breakpoints.up("sm")),
    isMd: useMediaQuery(theme.breakpoints.up("md")),
    isLg: useMediaQuery(theme.breakpoints.up("lg")),
    active: "xs",
  };
  if (breakpoints.isXs) breakpoints.active = "xs";
  if (breakpoints.isSm) breakpoints.active = "sm";
  if (breakpoints.isMd) breakpoints.active = "md";
  if (breakpoints.isLg) breakpoints.active = "lg";

  return breakpoints;
};
export default useBreakpoint;
