import { useState } from "react";
import { Box, Drawer, Typography, Button } from "@mui/material";
import { styled } from "@mui/system";
import { ReactComponent as ArrowLeftIcon } from "../../media/svg/icon/arrow-left.svg";

import MyThumbnail from "./MyThumbnail";

const TabPanelWrapper = styled(Box)(({ theme }) => ({
  "& .frame-container": {
    background: "linear-gradient(180deg, #58457E 0%, #BBACC1 100%)",
    border: "2px solid #58457E",
    borderRadius: 30,
    height: 524,
    position: "relative",
    overflow: "hidden",

    [theme.breakpoints.down("md")]: {
      height: 441,
    },

    "& img": {
      position: "absolute",
      border: "8px solid #B400AC",
      borderRadius: 30,
      top: 62,
      left: "15%",
    },
  },
}));

const MyDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "sx",
})(({ theme, open }) => ({
  position: "relative",
  height: "100%",
  borderRadius: 30,
  borderColor: "#58457E",
  ...(open === true && {
    left: 272,
    [theme.breakpoints.down("md")]: {
      left: 18,
    },
    [theme.breakpoints.down("sm")]: {
      left: 0,
    },
  }),
  ...(open !== true && {
    [theme.breakpoints.up("sm")]: {
      left: "90%",
    },
  }),

  "& .MuiPaper-root": {
    position: "relative",
    padding: "24px 32px",
    borderColor: "#58457E",
    borderWidth: "0px 0px 48px 2px",
    borderStyle: "solid",
    borderRadius: "0 30px 30px 0",
    height: "calc(100% - 96px)",
    overflow: "unset",
    [theme.breakpoints.down("sm")]: {
      padding: 24,
      display: "flex",
      borderTop: "2px solid #58457E",
      borderRadius: "0 0 30px 0",
      flexDirection: "column",
      bottom: 0,
      height: 100,
      top: "calc(100% - 196px)",
      ...(open === true && {
        top: "unset",
        height: "calc(100% - 96px)",
      }),
    },

    "& .slide-button": {
      position: "absolute",
      padding: "8px 24px",
      borderRadius: "30px 0 0 30px",
      background: "#FFF",
      borderColor: "#58457E",
      borderWidth: 2,
      borderStyle: "solid",
      width: 32,
      height: 86,
      left: -64,
      [theme.breakpoints.down("sm")]: {
        top: -36,
        right: 12,
        left: "unset",
        width: 32,
        height: 40,
        minWidth: "unset",
        padding: "8px 6px",
        transform: "rotate(90deg)",
        borderRadius: "10px 0 0 10px",
        justifyContent: "flex-start",
      },
      ...(open === true && {
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      }),
    },

    "& .title": {
      color: "#2C497F",
      fontSize: 80,
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        fontSize: 32,
      },
      [theme.breakpoints.down("sm")]: {
        width: 194,
      },
    },

    "& >.title": {
      ...(open === true && {
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      }),
    },

    "& .points-container": {
      overflowX: "auto",
      [theme.breakpoints.down("sm")]: {
        display: "none",
        ...(open === true && {
          display: "block",
        }),
      },
      "& .row": {
        display: "flex",
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
          overflowY: "auto",
          overflowX: "hidden",
        },
      },

      /* width */
      "&::-webkit-scrollbar": {
        width: 5,
      },
      /* Track */
      "&::-webkit-scrollbar-track": {
        borderRadius: 10,
      },

      /* Handle */
      "&::-webkit-scrollbar-thumb": {
        borderRadius: 10,
      },
    },
  },
}));

function TabPanel({ row, children, value, index, ...rest }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...rest}
    >
      <TabPanelWrapper>
        <Box className="frame-container">
          <img src={row.photo} alt={row.title} />
          <MyDrawer open={open} variant="permanent">
            <Button className="slide-button" onClick={handleOpen}>
              <ArrowLeftIcon />
            </Button>
            <Typography variant="h1" className="title">
              CareBridge {row.title}
            </Typography>
            <Box className="points-container">
              <Typography variant="h1" className="title visible-sm">
                CareBridge {row.title}
              </Typography>
              <Box className="row">
                {row.points.map((point, i) => (
                  <MyThumbnail key={i} index={i + 1} {...point} />
                ))}
              </Box>
            </Box>
          </MyDrawer>
        </Box>
      </TabPanelWrapper>
    </div>
  );
}

export default TabPanel;
