import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  InstapaperShareButton,
  FacebookIcon,
  LinkedinIcon,
  InstapaperIcon,
  TwitterIcon,
} from "react-share";
import { Box } from "@mui/material";
import { styled } from "@mui/system";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 8,
}));

const ShareButtons = ({ url }) => {
  return (
    <Wrapper>
      <FacebookShareButton url={url}>
        <FacebookIcon
          size={32}
          round
          bgStyle={{
            fill: "#fff",
            stroke: "#B400AC",
            strokeWidth: 2,
          }}
          iconFillColor="#58457E"
        />
      </FacebookShareButton>
      <InstapaperShareButton url={url}>
        <InstapaperIcon
          size={32}
          round
          bgStyle={{
            fill: "#fff",
            stroke: "#B400AC",
            strokeWidth: 2,
          }}
          iconFillColor="#58457E"
        />
      </InstapaperShareButton>
      <LinkedinShareButton url={url}>
        <LinkedinIcon
          size={32}
          round
          bgStyle={{
            fill: "#fff",
            stroke: "#B400AC",
            strokeWidth: 2,
          }}
          iconFillColor="#58457E"
        />
      </LinkedinShareButton>
      <TwitterShareButton url={url}>
        <TwitterIcon
          size={32}
          round
          bgStyle={{
            fill: "#fff",
            stroke: "#B400AC",
            strokeWidth: 2,
          }}
          iconFillColor="#58457E"
        />
      </TwitterShareButton>
    </Wrapper>
  );
};
export default ShareButtons;
