import HomeLayout from "./components/layouts/HomeLayout";
import Homepage from "./pages/Homepage";
import BlogListPage from "./pages/BlogListPage";
import BlogDetailPage from "./pages/BlogDetailPage";
import PricingPage from "./pages/PricingPage";
import FreeProgram from "./pages/FreeProgram";
import InquiryPartner from "./pages/InquiryPartner";

const routes = [
  {
    path: "/",
    element: <HomeLayout children={<Homepage />} />,
  },
  {
    path: "/resources/:categoryId?",
    element: <HomeLayout children={<BlogListPage />} />,
  },
  {
    path: "/articles/:slug",
    element: <HomeLayout children={<BlogDetailPage />} />,
  },
  {
    path: "/pricing",
    element: <HomeLayout children={<PricingPage />} />,
  },
  {
    path: "/free-program",
    element: <HomeLayout children={<FreeProgram />} />,
  },
  {
    path: "/inquiry-partner",
    element: <HomeLayout children={<InquiryPartner />} />,
  },
];

export default routes;
