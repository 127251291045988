import { Grid, Typography, Box } from "@mui/material";
import { styled } from "@mui/system";

const Wrapper = styled(Box)(({ theme }) => ({
  paddingTop: 112,
  paddingBottom: 80,
  [theme.breakpoints.down("sm")]: {
    paddingTop: 56,
    paddingBottom: 40,
  },
  h1: {
    color: "#58457E",
  },
  "& .MuiTypography-caption": {
    color: "#2C497F",
    paddingTop: 56,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 24,
    },
  },
}));

const BlogTitleSection = ({ title, description }) => {
  return (
    <Wrapper>
      <Grid container>
        <Grid item md={4} sm={12}>
          <Typography variant="h1">{title}</Typography>
        </Grid>

        <Grid
          item
          md={8}
          sm={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption">{description}</Typography>
        </Grid>
      </Grid>
    </Wrapper>
  );
};
export default BlogTitleSection;
