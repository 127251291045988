import { styled } from "@mui/system";
import { Box, Grid, Typography } from "@mui/material";
import ArrowRight from "../../media/svg/icon/double-arrow-right.svg";

import Graphic from "./Graphic";

const MyCardWrapper = styled(Box)(({ theme }) => ({
  borderWidth: "2px 2px 2px 12px",
  borderStyle: "solid",
  borderColor: "#58457E",
  borderRadius: 12,
  width: 580 - 12,
  height: 486,
  display: "flex",
  flexDirection: "column",

  "& >.MuiGrid-container": {
    padding: "16px 32px",
    color: "#2C497F",
    borderBottom: "2px solid #58457E",
    background: "#FFFFFF",
    height: 138,
    flex: "0 0 auto",
    borderRadius: "12px 12px 0 0",

    "& .Mui-Typography-normal": {
      fontSize: 16,
    },
  },

  "& .img": {
    display: "flex",
    width: "100%",
    flex: "1 1 auto",
    overflow: "hidden",
    position: "relative",

    "&::before": {
      content: `url(${ArrowRight})`,
      position: "absolute",
      top: 9,
      right: 12,
      display: "none",
      zIndex: 1,
      [theme.breakpoints.down("md")]: {
        display: "block",
      },
    },

    "& svg": {
      position: "absolute",
      width: "100%",
      top: -19,
    },
  },

  [theme.breakpoints.down("md")]: {
    width: 346 - 12,
    height: 322,

    "& >.MuiGrid-container": {
      padding: 16,
      height: 120,
      "& .MuiTypography-body-intro": {
        fontSize: 17,
      },
      "& .MuiTypography-normal": {
        fontSize: 13,
      },
    },
    "& .img svg": {
      top: "unset",
      height: "auto",
    },
  },
}));
const MyCard = ({ image, title, description }) => {
  return (
    <MyCardWrapper>
      <Grid container>
        <Grid item md={6} sm={12}>
          <Typography variant="body-intro">{title}</Typography>
        </Grid>
        <Grid item md={6} sm={12}>
          <Typography variant="normal">{description}</Typography>
        </Grid>
      </Grid>
      <Box className="img">
        <Graphic name={image} />
      </Box>
    </MyCardWrapper>
  );
};

export default MyCard;
