import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

const MyThumbnailWrapper = styled(Box)(({ theme }) => ({
  color: "#2C497F",
  display: "flex",
  flexDirection: "column",
  padding: 12,
  width: 206,
  flexBasic: "fit-content",
  flexShrink: 0,
  marginRight: 16,
  float: "left",
  [theme.breakpoints.down("md")]: {
    width: "calc(100% - 12px)",
  },

  "& .number": {
    border: "2px solid #B400AC",
    borderRadius: 30,
    width: 24,
    height: 24,
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "15px!important",
    },
  },
  "& .caption": {
    marginTop: 16,
    marginBottom: 16,
    [theme.breakpoints.down("md")]: {
      fontSize: 17,
      marginTop: 8,
      marginBottom: 8,
    },
  },
  "& .description": {
    [theme.breakpoints.down("md")]: {
      fontSize: 15,
    },
  },
}));
const MyThumbnail = ({ caption, description, index }) => {
  return (
    <MyThumbnailWrapper>
      <Box className="number">
        <Typography variant="normal-bold">{index}</Typography>
      </Box>
      <Typography variant="medium-bold" className="caption">
        {caption}
      </Typography>
      <Typography variant="normal" className="description">
        {description}
      </Typography>
    </MyThumbnailWrapper>
  );
};

export default MyThumbnail;
