import Countdown from "react-countdown";
import { Box, Typography, useTheme } from "@mui/material";

import MyClock from "./MyClock";

const MyCountDown = ({ title, time }) => {
  const theme = useTheme();
  const renderer = ({ completed, ...clock }) => {
    if (completed) {
      return <></>;
    } else {
      return <MyClock {...clock} />;
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: 38,
        }}
      >
        <Typography
          variant="normal"
          sx={{
            textAlign: "center",
            color: "#2C497F",
            [theme.breakpoints.down("sm")]: {
              fontSize: 13,
            },
          }}
        >
          {title}
        </Typography>
      </Box>
      <Countdown date={Date.now() + +time} renderer={renderer} />
    </>
  );
};
export default MyCountDown;
