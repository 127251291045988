import { useEffect, useState, useCallback } from "react";
import qs from "qs";
import { useLocation } from "react-router-dom";
//import MuiPhoneNumber from "material-ui-phone-number";
import { Box, FormControl, InputLabel } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";

import MyButton from "../tools/MyButton";
// import MyCountDown from "../tools/MyCountDown";
import BootboxAlertContent from "./BootboxAlertContent";

import BootstrapInput from "./BootstrapInput";
import useTicket from "../../helpers/useTicket";
// import * as RegisterModel from "../../models/Register";

const TrialForm = ({ bootboxRef }) => {
  const location = useLocation();
  //eslint-disable-next-line
  const ticket = useTicket();
  const tickInfo = ticket.getTicket();
  const [initialValues, setInitialValues] = useState(tickInfo);

  // const handleGoMeeting = (e) => {
  //   e.preventDefault();
  //   window.open("https://meetings.hubspot.com/david2085");
  // };

  const onSubmit = useCallback(
    async (values, { setStatus, setSubmitting, setErrors }) => {
      setSubmitting(true);
      if (!values?.email) {
        bootboxRef?.current?.content(
          <BootboxAlertContent
            message="提交錯誤！請重新嘗試"
            bootboxRef={bootboxRef}
          />
        );
        bootboxRef?.current?.show();
        setSubmitting(true);
        return false;
      }
      try {
        await ticket.submitTicket(values);
        bootboxRef?.current?.content(
          <BootboxAlertContent
            message="完成提交！感謝您的支持。"
            bootboxRef={bootboxRef}
          />
        );
        bootboxRef?.current?.show();
      } catch (error) {
        bootboxRef?.current?.content(
          <BootboxAlertContent
            message="完成提交！感謝您的支持。"
            bootboxRef={bootboxRef}
          />
        );
        bootboxRef?.current?.show();
        console.log("error: ", error);
      } finally {
        setSubmitting(false);
      }
    },
    [bootboxRef, ticket]
  );

  useEffect(() => {
    const email = qs.parse(location.search, { ignoreQueryPrefix: true }).email;
    setInitialValues({ ...initialValues, email: email });
    // eslint-disable-next-line
  }, [location]);

  //TODO: Form setting
  return (
    <Formik
      autoComplete="off"
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        email: Yup.string().required("電郵地址為必填"),
        firstname: Yup.string().required("名字為必填"),
        phone: Yup.string().required("電話為必填"),
        region: Yup.string().required("地區為必填"),
        offers: Yup.mixed().required("請同意協議"),
        candidates: Yup.mixed().required("請同意協議"),
      })}
      onSubmit={onSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        status,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box className="TrialForm">
            {errors && console.log(errors)}
            <p className="text-[#2C497F] text-lg">
              為提供您迅速而確實的服務，請務必正確並完整填寫以下資訊。 標記
              <span className="text-lg text-red-500">*</span>為必填欄位
            </p>
            <FormControl fullWidth>
              <InputLabel sx={{ fontWeight: "bold" }} shrink required>
                您的名稱:
              </InputLabel>
              <BootstrapInput
                fullWidth
                required
                placeholder="輸入名稱"
                error={Boolean(touched.firstname && errors.firstname)}
                onBlur={handleBlur}
                onChange={handleChange}
                name="firstname"
                type="text"
                value={values?.firstname || ""}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel sx={{ fontWeight: "bold" }} shrink required>
                您的電郵地址:
              </InputLabel>
              <BootstrapInput
                fullWidth
                required
                placeholder="輸入電郵地址"
                error={Boolean(touched.email && errors.email)}
                onBlur={handleBlur}
                onChange={handleChange}
                name="email"
                type="text"
                value={values?.email || ""}
                inputProps={{
                  autoComplete: "new-password",
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel sx={{ fontWeight: "bold" }} shrink required>
                您的電話:
              </InputLabel>
              <BootstrapInput
                fullWidth
                required
                placeholder="輸入電話"
                error={Boolean(touched.phone && errors.phone)}
                onBlur={handleBlur}
                onChange={handleChange}
                name="phone"
                type="text"
                value={values?.phone || ""}
                inputProps={{
                  autoComplete: "new-password",
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel sx={{ fontWeight: "bold" }} shrink required>
                您的地區:
              </InputLabel>
              <BootstrapInput
                fullWidth
                required
                placeholder="輸入地區"
                error={Boolean(touched.region && errors.region)}
                onBlur={handleBlur}
                onChange={handleChange}
                name="region"
                type="text"
                value={values?.region || ""}
                inputProps={{
                  autoComplete: "new-password",
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel sx={{ fontWeight: "bold" }} shrink>
                您的公司名稱:
              </InputLabel>
              <BootstrapInput
                fullWidth
                placeholder="輸入公司名稱"
                error={Boolean(touched.companyName && errors.companyName)}
                onBlur={handleBlur}
                onChange={handleChange}
                name="companyName"
                type="text"
                value={values?.companyName || ""}
                inputProps={{
                  autoComplete: "new-password",
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel sx={{ fontWeight: "bold" }} shrink>
                公司或個人簡介:
              </InputLabel>
              <textarea
                name="content"
                className="rounded-[32px] mt-[36px] relative bg-[#fcfcfb] border border-[#ced4da] text-[16px] py-[6px] px-[12px] transform transition-all focus:shadow focus:border-[#BBACC1] border-[#BBACC1] placeholder:opacity-20"
                placeholder="輸入公司或個人簡介"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.content || ""}
              />
            </FormControl>
            {/* <FormControl fullWidth>
              <InputLabel shrink required>
                創建密碼
              </InputLabel>
              <BootstrapInput
                fullWidth
                required
                error={Boolean(touched.password && errors.password)}
                onBlur={handleBlur}
                onChange={handleChange}
                name="password"
                type="password"
                autoComplete="new-password"
                value={values?.password || ""}
              />
            </FormControl>*/}
            {/*     <FormControl fullWidth>
              <InputLabel shrink required>
                確認密碼
              </InputLabel>
              <BootstrapInput
                fullWidth
                required
                error={Boolean(
                  touched.confirm_password && errors.confirm_password,
                )}
                onBlur={handleBlur}
                onChange={handleChange}
                name="confirm_password"
                type="password"
                autoComplete="new-password"
                value={values?.confirm_password || ""}
              />
            </FormControl>*/}
            {/*<FormControl fullWidth>
              <InputLabel shrink required>
                輸入驗證碼
              </InputLabel>
              <BootstrapInput
                fullWidth
                required
                placeholder="驗證碼"
                error={Boolean(touched.vcode && errors.vcode)}
                onBlur={handleBlur}
                onChange={handleChange}
                name="vcode"
                type="vcode"
                value={values?.vcode || ""}
              />
            </FormControl>*/}
            <div className="leading-6 mt-6 flex gap-4  flex-col text-[#2C497F] text-base ">
              <p className="font-bold">個人資料蒐集、處理及利用同意條款</p>
              <p className="leading-6">
                Marstree information technology Ltd.
                (以下簡稱本公司）為評估業務合作申請及聯繫業務合作事項之目的範圍內，依據個人資料保護法等相關法規以及本公司之----
                {/* TODO: policy link import */}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://carebridge.app/articles/carebridge-privacy"
                  className="text-blue-600"
                >
                  隱私權政策
                </a>
                ，向尋求
                與Marstree相關服務合作的企業蒐集、處理及利用以下個人資料，並提供予受本公司委託處理及利用個人資料之公司及其他主辦單位之子公司或關係企業（或受託處理相關事務者），以確保相關服務團隊能即時與您聯繫。
              </p>
              <ol className="flex flex-col gap-1">
                <li>
                  1.個人資料類別
                  <ul>
                    <li className="ml-4">
                      <span className="text-[#2C497F]">・</span>
                      姓名、電子郵件、電話號碼、公司名稱、部門名稱、職位及其他您主動提供之內容等可直接或間接識別個人之資料。
                    </li>
                  </ul>
                </li>
                <li>
                  2.個人資料蒐集與利用目的
                  <ul>
                    <li className="ml-4">
                      <span className="text-[#2C497F]">・</span>
                      確認您個人身分，確保有效聯繫管道。
                    </li>
                    <li className="ml-4">
                      <span className="text-[#2C497F]">・</span>
                      留存紀錄以備日後解決糾紛。
                    </li>
                  </ul>
                </li>
                <li>
                  3.個人資料利用期間
                  <ul>
                    <li className="ml-4">
                      <span className="text-[#2C497F]">・</span>
                      自您提交表單時起3個月內。
                    </li>
                  </ul>
                </li>
                <li>
                  4.個人資料利用地區
                  <ul>
                    <li className="ml-4">
                      <span className="text-[#2C497F]">・</span>
                      限於本公司、火星集團暨相關子公司、關係企業、（或受託處理相關事務者）從事業務的國家及地區於相關法律或命令許可範圍內利用。
                    </li>
                  </ul>
                </li>
                <li>
                  5.個人資料利用對象及方式
                  <ul>
                    <li className="ml-4">
                      <span className="text-[#2C497F]">・</span>
                      本公司將委由公司就前述個人資料進行業務合作申請評估，並請相關服務團隊透過電子郵件或電話號碼與您聯繫。
                    </li>
                  </ul>
                </li>
              </ol>
              <p>
                根據個人資料保護法之規定，您得就您的個人資料請求查詢或閱覽、請求製給複製本、請求補充或更正、請求停止蒐集、處理或利用及請求刪除之權利。
              </p>
              <p>
                由於本公司必須蒐集上述個人資料，才能評估您的申請並與您聯絡，若您不願意提供相關個人資料予本公司，本公司將無法為您評估業務合作之申請，或進一步與您聯繫相關業務合作事宜，或成為合作夥伴。
              </p>
              <p>
                其他有關本公司對個人資料之蒐集、處理及利用，請參考本公司的
                {/* TODO: policy link import */}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://carebridge.app/articles/carebridge-privacy"
                  className="text-blue-600"
                >
                  隱私權政策
                </a>
                。
              </p>
              {/* TODO: checkbox for accept policy */}
              <fieldset className="mt-4 mb-6">
                <legend className="sr-only">Notifications</legend>
                <div className="space-y-5">
                  <div className="relative flex items-start">
                    <div className="flex items-center h-6">
                      <input
                        id="candidates"
                        aria-describedby="candidates-description"
                        name="candidates"
                        type="checkbox"
                        value="accept"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600"
                      />
                    </div>
                    <div className="ml-3 leading-6">
                      <p id="candidates-description" className="">
                        同意Marstree information technology
                        Ltd.依據上述「個人資料蒐集、處理及利用同意條款」蒐集、處理、利用本人之個人資料。若提供非本人之個人資料，我擔保已取得有權為此同意之人之完整、合法的授權為此同意。
                      </p>
                    </div>
                  </div>
                  <div className="relative flex items-start">
                    <div className="flex items-center h-6">
                      <input
                        id="offers"
                        aria-describedby="offers-description"
                        name="offers"
                        type="checkbox"
                        value="accept"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600"
                      />
                    </div>
                    <div className="ml-3 leading-6">
                      <p id="offers-description" className="">
                        我已閱讀並同意（或已取得有權為此同意之人之完整、合法的授權）上述聲明內容。
                      </p>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </Box>
          <Box className="footer">
            <MyButton
              type="reset"
              name="form-button"
              variant="outlined"
              color="secondary"
            >
              重新輸入
            </MyButton>
            <Box className="btn-group">
              <MyButton
                name="form-button"
                variant="contained"
                color="primary"
                type="submit"
                isblock="isblock"
                disabled={isSubmitting}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;提交&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </MyButton>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};
export default TrialForm;
