import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

const QuestionWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "sx",
})(({ theme, open }) => ({
  color: "#FFF",
  paddingBottom: 42,

  "& .MuiTypography-h3": {
    cursor: "pointer",
    transition: "opacity .1s cubic-bezier(0.1, 0.7, 1.0, 0.1)",
    ...(open === true
      ? {
          fontSize: 30,
          opacity: 1,
        }
      : {
          fontSize: 40,
          opacity: 0.3,
        }),
    [theme.breakpoints.down("md")]: {
      ...(open===true) ? {
        fontSize: 20
      }: {
          fontSize: 20
        }
    }
  },

  "& .MuiTypography-medium": {
    transition: "display .3s cubic-bezier(0.1, 0.7, 1.0, 0.1)",
    ...(open === true
      ? {
          display: "block",
          opacity: 1,
        }
      : {
          display: "none",
          opacity: 0,
        }),
    [theme.breakpoints.down("md")]: {
        fontSize: 17
    }
  },
}));

const Question = ({ question, answer }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <QuestionWrapper open={open}>
      <Typography
        variant="h3"
        onClick={handleOpen}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
      >
        {question}
      </Typography>
      <Typography
        variant="medium"
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
      >
        {answer}
      </Typography>
    </QuestionWrapper>
  );
};
export default Question;
