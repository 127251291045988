import { styled } from "@mui/system";
import { Box } from "@mui/material";

const MyClockWrapper = styled(Box)(({ theme }) => ({
  fontSize: 64,
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    fontSize: 32,
  },

  "& .number": {
    background: "#4D3f6e",
    border: "2px solid #58457E",
    borderRadius: 10,
    padding: "20px 12px",
    color: "#FFFFFF",
    width: 105,
    textAlign: "center",
    fontWeight: 700,
  },

  "& .colun": {
    padding: "20px 12px",
  },
}));
const MyClock = ({ hours, minutes, seconds }) => {
  return (
    <MyClockWrapper>
      <Box className="number">{String(hours).padStart(2, "0")}</Box>
      <Box className="colun">:</Box>
      <Box className="number">{String(minutes).padStart(2, "0")}</Box>
      <Box className="colun">:</Box>
      <Box className="number">{String(seconds).padStart(2, "0")}</Box>
    </MyClockWrapper>
  );
};
export default MyClock;
