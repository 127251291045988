import { useNavigate } from "react-router-dom";
import { Container, Box, Typography, Grid } from "@mui/material";
import { styled } from "@mui/system";

import MyButton from "../tools/MyButton";
import Question from "../tools/Question";

const ProblemSectionWrapper = styled(Box)(({ theme }) => ({
  background: "rgba(50, 61, 109, 0.5)",
  border: "0.5px solid rgba(255, 255, 255, 0.3)",
  boxShadow: "0px 50px 100px rgba(0, 0, 0, 0.25)",
  backdropFilter: "blur(20px)",
  borderRadius: 20,
  marginBottom: 112,

  "& .wrapper-title": {
    padding: "112px 56px",
    borderBottom: "2px solid #FFFFFF",

    "& .MuiGrid-item:first-of-type": {
      [theme.breakpoints.down("sm")]: {
        paddingBottom: 32,
      },
    },

    "& h2": {
      color: "#FFF",
    },

    [theme.breakpoints.down("md")]: {
      padding: "32px 16px",

      "& h2": {
        fontSize: 30,
      },
    },
  },

  "& .wrapper-content": {
    padding: "48px 56px 112px 56px",

    [theme.breakpoints.down("md")]: {
      padding: "48px 16px",
    },

    "& >.MuiGrid-container": {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column-reverse",
      },
    },

    "& .MuiGrid-item:last-child": {
      paddingLeft: 56,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
        paddingBottom: 36,
      },

      "& img": {
        width: "100%",
        objectFit: "contain",
        filter: "drop-shadow(0px 30px 60px rgba(0, 0, 0, 0.25))",
      },
    },
  },
}));
const ProblemSection = ({ title, questions, photo, ...rest }) => {
  const navigate = useNavigate();
  const handleClickButton = (e) => {
    e.preventDefault();
    navigate("/#solution");
  };

  return (
    <Container maxWidth="lg">
      <ProblemSectionWrapper id="problem">
        <Box className="wrapper-title">
          <Grid container>
            <Grid item xs={12} sm={6} md={8}>
              <Typography variant="h2">{title}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <MyButton name="problem-button" onClick={handleClickButton}>
                立即了解如何解決問題
              </MyButton>
            </Grid>
          </Grid>
        </Box>
        <Box className="wrapper-content">
          <Grid container>
            <Grid item xs={12} sm={6}>
              {questions.map((q, i) => (
                <Question {...q} key={i} />
              ))}
              <MyButton name="problem-button2" onClick={handleClickButton}>
                立即了解如何解決問題
              </MyButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <img src={photo} alt="CareBridge 診所管理系統" />
            </Grid>
          </Grid>
        </Box>
      </ProblemSectionWrapper>
    </Container>
  );
};
export default ProblemSection;
