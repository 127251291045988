import { Box, Typography, Grid, Container } from "@mui/material";
import { styled } from "@mui/system";

import MyButton from "../tools/MyButton";
import EmailSubscription from "../sections/EmailSubscription";

import useVariants from "../../helpers/useVariants";

const HeroSectionWrapper = styled(Box)(({ theme }) => ({
  paddingTop: 144,
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    paddingTop: 64,
  },

  "& .MuiTypography-root": {
    color: "#2C497F",
  },
  "& .article": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    [theme.breakpoints.up("md")]: {
      paddingRight: 64,
    },
    [theme.breakpoints.down("md")]: {
      paddingBottom: 32,
      paddingRight: 30,
    },

    "& [name='new-button']": {
      marginBottom: 16,
    },
  },
  "& .photo": {
    img: {
      width: "100%",
    },
  },

  ".big-title": {
    color: "#58457E",
    [theme.breakpoints.up("md")]: {
      fontSize: 112,
    },
  },
  ".big-ch-title": {
    lineHeight: 1.4,
    color: "rgba(88, 69, 126, 0.3)",
    [theme.breakpoints.up("md")]: {
      fontSize: 112,
    },
    [theme.breakpoints.down("md")]: {
      paddingBottom: 12,
    },
  },
  ".content": {
    [theme.breakpoints.down("md")]: {
      textAlign: "justify",
    },
  },
}));

const HeroSection = ({ title, content, photo, bootboxRef }) => {
  const variants = useVariants({ md: "caption", sm: "body-intro" });

  return (
    <Container maxWidth="lg">
      <HeroSectionWrapper>
        <Grid container>
          <Grid item sm={12} md={5} className="article">
            <MyButton name="new-button">CareBridge V3</MyButton>
            <Grid
              container
              className="hidden-md"
              sx={{
                paddingTop: "12px",
              }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <Typography variant="h1" className="big-title">
                  CareBridge
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Typography variant="h1" className="big-ch-title">
                  診所管理系統
                </Typography>
              </Grid>
            </Grid>
            <Typography variant={variants}>{title}</Typography>
            <Typography variant="normal" className="content">
              {content}
            </Typography>
          </Grid>
          <Grid item sm={12} md={7} className="photo visible-md">
            <img src={photo} alt="CareBridge 診所管理系統" />
          </Grid>
        </Grid>

        <EmailSubscription className="hidden-md" bootboxRef={bootboxRef} />
        <Grid container>
          <Grid
            item
            sm={12}
            md={7}
            className="photo hidden-md"
            sx={{
              paddingTop: "32px",
              paddingBottom: "64px",
            }}
          >
            <img src={photo} alt="CareBridge 診所管理系統" />
          </Grid>
          <Grid item md={6} className="visible-md">
            <Typography variant="h1" className="big-title">
              CareBridge
            </Typography>
          </Grid>
          <Grid item md={6} className="visible-md">
            <Typography variant="h1" className="big-ch-title">
              診所管理系統
            </Typography>
          </Grid>
        </Grid>
      </HeroSectionWrapper>
    </Container>
  );
};

export default HeroSection;
